.dropdown {
  @apply relative inline-block;

  .dropdown-count {
    @apply ml-1.5 rounded bg-gray-200 dark:bg-gray-700 py-0.5 px-1.5 text-xs font-sans tabular-nums text-gray-700 dark:text-gray-400;
  }

  .dropdown-arrow {
    @apply flex items-center;

    svg-icon {
      @apply transition ease-in-out duration-150;

      &.clicked {
        @apply transform rotate-180;
      }
    }
  }
}

.divider {
  @apply pb-0.5 mt-0.5 border-t border-gray-200 dark:border-white/20;
}

.dropdown-menu {
  min-width: 10rem;
  width: max-content;
  @apply rounded-md shadow-lg bg-white dark:bg-gray-700 dark:shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none p-1.5 z-30 #{!important};

  .dropdown-title {
    @apply
    px-4
    py-1
    block
    text-sm
    font-sans
    text-gray-700
    dark:text-slate-200
    hover:bg-white
    dark:hover:text-slate-200
    dark:hover:bg-gray-700
    rounded-md;
  }

  .dropdown-item-container {
    @apply max-h-[26rem] h-full overflow-y-auto -mx-1.5;

    .dropdown-item {
      @apply
      px-4
      py-1
      mx-1.5
      block
      text-sm
      font-sans
      text-gray-700
      hover:cursor-pointer
      dark:text-slate-300
      dark:hover:text-white
      hover:bg-gray-100
      dark:hover:bg-gray-600
      rounded-md;

      &.active {
        @apply bg-gray-100 dark:bg-gray-600;
      }

      &.disabled {
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .dropdown-first-item {
    pointer-events: none;
    cursor: default;
    @apply
    px-4
    py-1
    block
    text-sm
    font-sans
    text-gray-700
    dark:text-slate-300
    rounded-md;
  }

  .dropdown-item {
    @apply
    px-4
    py-1
    block
    text-sm
    font-sans
    text-gray-700
    hover:cursor-pointer
    dark:text-slate-300
    dark:hover:text-white
    hover:bg-gray-100
    dark:hover:bg-gray-600
    rounded-md;

    &.active {
      @apply bg-gray-100 dark:bg-gray-600;
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
    }
  }

  &.lg {
    .dropdown-item {
      @apply relative flex gap-x-6 rounded-md p-4 hover:bg-gray-50 dark:hover:bg-gray-600;

      .dropdown-item-icon {
        @apply mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 dark:bg-gray-600;

        .drop-icon {
          @apply text-gray-600 dark:text-gray-50;
        }
      }

      .link {
        @apply text-gray-900 hover:text-indigo-600 dark:text-white dark:hover:text-indigo-400;
      }
    }
  }
}

.ant-dropdown-menu-sub {
  @apply py-0 rounded-lg;
}
