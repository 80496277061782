.side-nav-secondary {
  min-height: calc(100vh - 105px);
  height: 100%;
  @apply hidden lg:flex lg:flex-col w-64 h-full dark:bg-gray-800 border-r border-gray-200 dark:border-white/20;

  .side-nav-secondary-title {
    @apply h-16 flex items-center justify-between gap-4 pl-6 pr-4;

    h5 {
      @apply font-sans text-slate-900 dark:text-white text-base;
    }
  }

  .side-nav-secondary-links {
    @apply px-4 space-y-0.5 flex-1;

    h5 {
      @apply font-sans text-slate-900 dark:text-white text-base py-6;
    }

    .nav-link {
      @apply
      text-slate-700
      dark:text-slate-300
      flex
      items-center
      justify-between
      px-2
      py-2
      text-sm
      max-h-[40px]
      rounded-md;

      &:hover {
        @apply text-gray-900 bg-gray-100 dark:text-white dark:bg-slate-700;
      }

      &.active {
        @apply bg-gray-100 dark:bg-slate-700 text-slate-900 dark:text-white;
      }

      .link {
        @apply flex items-center;
        .dot {
          @apply flex-shrink-0 w-2.5 h-2.5 rounded-full mr-2;
        }
      }

    }
  }

  .side-nav-secondary-footer {
    @apply px-4 py-3;
  }
}
