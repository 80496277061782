.button {
  @apply font-sans focus:outline-none whitespace-nowrap w-full flex items-center justify-center;

  &.radius-round {
    @apply rounded-md;
  }

  &.radius-circle {
    @apply rounded-full;
  }

  &.radius-none {
    @apply rounded-none
  }

  &.in-group {
    @apply rounded-tl-none rounded-bl-none border border-gray-200 dark:border-gray-600 border-l-0 #{!important};
  }

  &.default {
    @apply bg-gray-50 dark:bg-slate-700 hover:bg-gray-100 dark:hover:bg-slate-600 active:bg-gray-100 dark:active:bg-gray-500 text-gray-600 dark:text-gray-100;

    svg-icon {
      @apply text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;
    }
  }

  &.solid {
    @apply bg-purple-600 hover:bg-purple-500 active:bg-purple-700 text-white;

    &.danger {
      @apply bg-rose-600 hover:bg-rose-500 active:bg-rose-700 text-white #{!important};
    }

    &.warning {
      @apply bg-orange-500 hover:bg-orange-400 active:bg-orange-600 text-white #{!important};
    }

    &.success {
      @apply bg-emerald-500 hover:bg-emerald-400 active:bg-emerald-600 text-white #{!important};
    }

    &.info {
      @apply bg-sky-500 hover:bg-sky-400 active:bg-sky-600 text-white #{!important};
    }
  }

  &.twotone {
    @apply bg-purple-50 dark:bg-purple-500 dark:bg-opacity-20 hover:bg-purple-100 dark:hover:bg-purple-500 dark:hover:bg-opacity-30 active:bg-purple-200 dark:active:bg-purple-500 dark:active:bg-opacity-40 text-purple-600 dark:text-purple-50;

    &.danger {
      @apply bg-rose-50 dark:bg-rose-500 dark:bg-opacity-20 hover:bg-rose-100 dark:hover:bg-rose-500 dark:hover:bg-opacity-30 active:bg-rose-200 dark:active:bg-rose-500 dark:active:bg-opacity-40 text-rose-600 dark:text-rose-50 #{!important};
    }

    &.warning {
      @apply bg-orange-50 dark:bg-orange-500 dark:bg-opacity-20 hover:bg-orange-100 dark:hover:bg-orange-500 dark:hover:bg-opacity-30 active:bg-orange-200 dark:active:bg-orange-500 dark:active:bg-opacity-40 text-orange-600 dark:text-orange-50 #{!important};
    }

    &.success {
      @apply bg-emerald-50 dark:bg-emerald-500 dark:bg-opacity-20 hover:bg-emerald-100 dark:hover:bg-emerald-500 dark:hover:bg-opacity-30 active:bg-emerald-200 dark:active:bg-emerald-500 dark:active:bg-opacity-40 text-emerald-600 dark:text-emerald-50 #{!important};
    }

    &.info {
      @apply bg-sky-50 dark:bg-sky-500 dark:bg-opacity-20 hover:bg-sky-100 dark:hover:bg-sky-500 dark:hover:bg-opacity-30 active:bg-sky-200 dark:active:bg-sky-500 dark:active:bg-opacity-40 text-sky-800 dark:text-sky-50 #{!important};
    }
  }

  &.plain {
    @apply bg-transparent border border-transparent hover:bg-gray-50 dark:hover:bg-slate-700 active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500 text-gray-600 dark:text-gray-100 dark:hover:text-white;

    &.menu-icon {
      @apply bg-red-600 p-2 hover:bg-red-600 border-none focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600 #{!important};
    }

    svg-icon {
      @apply text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;
    }
  }

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

}

.button-upload {
  @apply rounded-md;

  &.upload {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @apply h-10 align-middle leading-none whitespace-nowrap transition-all duration-100 appearance-none cursor-pointer select-none px-3;

    .upload-label {
      @apply flex items-center pointer-events-none relative font-sans;

      svg-icon {
        @apply text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;
      }
    }

    .upload-file-input {
      @apply absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0;
    }
  }

  &.upload-input {
    @apply bg-transparent border border-transparent hover:bg-gray-50 dark:hover:bg-slate-600 active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500 text-gray-600 dark:text-gray-100;
  }
}
