.nav-bar-aside {
  min-height: calc(100vh - 105px);
  height: 100%;
  @apply hidden xl:block xl:flex-shrink-0 xl:order-first h-full;

  .nav-bar-aside-container {
    @apply h-full relative flex flex-col w-64 border-r border-gray-200 bg-white dark:bg-gray-800 dark:border-gray-600;

    .nav-bar-aside-selector {
      //@apply border-b border-gray-200 flex-shrink-0;
      @apply flex-shrink-0;

      .nav-bar-aside-selector-container {
        @apply h-16 px-6 flex flex-col justify-center;
      }
    }

    .nav-bar-aside-filter {
      @apply flex flex-col flex-grow pb-4 overflow-y-auto;
    }
  }
}

.filter-item {
  @apply
  text-slate-700
  dark:text-slate-300
  flex
  items-center
  px-2
  py-2
  text-sm
  rounded-md;

  svg-icon {
    @apply text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0;
  }

  &:hover {
    @apply text-gray-900 bg-gray-100 dark:text-white dark:bg-slate-700;
  }

  &.active {
    @apply bg-gray-100 dark:bg-slate-700 text-slate-900 dark:text-white;
  }
}

.chart-item {
  @apply block w-full px-3 py-2 flex-shrink-0 hover:bg-gray-100 hover:text-gray-900 rounded-md;

  .chart-title {
    @apply text-sm font-sans text-gray-600;
  }

  .chart-subtitle {
    @apply text-xs font-sans text-gray-400;
  }
}
