nz-select {
  width: 100%;

  &.assignees {
    nz-select-item {
      height: 32px !important;
      @apply rounded-md #{!important};
    }
  }

  nz-select-top-control {
    height: 100%;
    min-height: 2.5rem;
    width: 100%;
    padding: 0.325rem 0.75rem;
    transition: background 0.1s;
    @apply bg-white border border-gray-200 rounded-md shadow-sm text-sm dark:text-slate-400 dark:border-slate-600 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-800 dark:hover:text-slate-200 focus:ring-purple-600 #{!important};

    &:hover {
      nz-select-arrow {
        @apply text-gray-800 dark:text-slate-600 #{!important};
      }
    }

    nz-select-item {
      display: flex;
      align-items: center;
    }

    nz-select-search {
      input {
        height: 100% !important;

        &::placeholder {
          font-size: 14.5px;
          vertical-align: middle !important;
        }
      }
    }

    nz-select-placeholder {
      display: flex;
      align-items: center;
    }
  }

  &.ant-select-focused {
    nz-select-top-control {
      @apply bg-white dark:bg-gray-800 border-purple-600 ring-1 ring-purple-600 #{!important};
    }
  }

  nz-select-arrow {
    @apply dark:text-slate-600 #{!important};
  }
}

nz-option-container {
  @apply rounded-md bg-white dark:bg-gray-800 dark:border dark:border-slate-600 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none #{!important};

  nz-option-item {
    @apply block px-4 py-2 text-sm text-gray-700 dark:text-slate-400 #{!important};

    &.ant-select-item-option-selected {
      @apply bg-gray-100 dark:text-white dark:bg-gray-700 #{!important};
    }

    &.ant-select-item-option-active {
      @apply bg-gray-100 dark:text-white dark:bg-gray-700 #{!important};
    }
  }
}

nz-input-group {
  .ant-input-wrapper {
    .ant-input-group-addon {
      padding: 0 !important;
      border-width: 0;
      @apply bg-white dark:bg-gray-800;

      nz-select {
        nz-select-top-control {
          @apply rounded-tr-none rounded-br-none border-r-0 #{!important};

          &:focus {
            @apply border-r-2 #{!important};
          }
        }
      }
    }

    .ant-input:hover {
      @apply bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-slate-200 border-gray-200 dark:border-slate-600 #{!important};
    }

    .ant-input:focus {
      @apply bg-white dark:text-white dark:bg-gray-800 border-purple-600 ring-purple-600 #{!important};
    }
  }
}

.ant-select-item-option {
  display: flex !important;
  flex-direction: row;
}


//nz-select {
//  @apply w-full;
//
//  &.ant-select-multiple {
//    nz-select-top-control {
//      .ant-select-selection-item {
//        @apply flex items-center border-0 text-sm bg-gray-100 dark:bg-gray-600 rounded-md #{!important};
//
//        .ant-select-selection-item-remove {
//          @apply flex items-center text-gray-500 dark:text-gray-400;
//        }
//      }
//    }
//  }
//
//  &.select-invalid {
//    nz-select-top-control {
//      @apply ring-1 ring-red-500 border-red-500 #{!important};
//    }
//  }
//
//  &.select-sm {
//    nz-select-top-control {
//      @apply text-sm h-9 #{!important};
//    }
//  }
//
//  &.select-md {
//    nz-select-top-control {
//      @apply text-base h-11 #{!important};
//    }
//  }
//
//  &.select-lg {
//    nz-select-top-control {
//      @apply text-lg h-11 #{!important};
//    }
//  }
//
//  &.select-group {
//    max-width: 120px;
//    nz-select-top-control {
//      @apply border-l-0 rounded-tl-none rounded-bl-none #{!important};
//    }
//  }
//
//  &.ant-select-focused {
//    nz-select-top-control {
//      box-shadow: none !important;
//      @apply
//      flex
//      items-center
//      focus:ring-purple-600
//      focus-within:ring-purple-600
//      focus-within:border-purple-600
//      focus:border-purple-600 #{!important};
//    }
//  }
//
//  nz-select-top-control {
//    @apply
//    flex
//    items-center
//    border
//    border-gray-200
//    dark:border-gray-600
//    dark:bg-transparent
//    rounded-md
//    w-full
//    py-2 px-3
//    focus:outline-none
//    focus:ring-1
//    focus-within:ring-1
//    appearance-none
//    transition
//    duration-150
//    text-gray-500
//    dark:text-gray-100
//    ease-in-out #{!important};
//
//    .ant-select-selection-item {
//      @apply flex items-center #{!important};
//    }
//
//    nz-select-search {
//      input {
//        @apply dark:text-gray-100 dark:placeholder-gray-100 h-full #{!important};
//
//        &::placeholder {
//          vertical-align: middle !important;
//          @apply dark:text-gray-400 dark:placeholder-gray-400 #{!important};
//        }
//      }
//    }
//
//    nz-select-placeholder {
//      @apply flex items-center text-gray-500 placeholder-gray-500 #{!important};
//    }
//  }
//
//  nz-select-arrow {
//    &.ant-select-arrow {
//      @apply dark:text-white #{!important};
//    }
//  }
//
//  .ant-select-clear {
//    @apply bg-white dark:bg-gray-800 flex items-center #{!important};
//
//    i {
//      @apply text-gray-500 dark:text-gray-300 #{!important};
//    }
//  }
//}
//
//nz-option-container {
//  @apply rounded-md bg-white dark:bg-gray-700 shadow-lg py-0 #{!important};
//
//  nz-option-item {
//    @apply
//    dark:text-gray-400
//    dark:hover:text-white
//    hover:bg-gray-50
//    dark:hover:bg-gray-600 #{!important};
//
//    .ant-select-item-option-content {
//      flex: auto;
//      height: 30px;
//      display: flex;
//      align-items: center;
//    }
//
//    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
//      @apply bg-transparent hover:bg-gray-50 dark:hover:bg-gray-600;
//    }
//
//    &.ant-select-item-option-selected {
//      @apply bg-gray-100 dark:bg-gray-500 dark:text-white #{!important};
//    }
//
//    &.ant-select-item-option-disabled {
//      @apply opacity-40 dark:opacity-30 hover:bg-transparent cursor-not-allowed #{!important};
//    }
//  }
//}
//
//.custom-footer-option {
//  @apply
//  bg-gray-50
//  border-t
//  border-gray-900/5
//  dark:border-white/10
//  dark:bg-gray-700;
//
//  .footer-item {
//    @apply
//    p-3
//    flex
//    items-center
//    justify-start
//    text-gray-900
//    dark:text-gray-400
//    dark:hover:text-white
//    hover:bg-gray-100
//    dark:hover:bg-gray-600;
//  }
//}
