.quick-item {
  @apply flex items-center p-2 rounded-md bg-white dark:bg-gray-800 text-slate-500 hover:bg-gray-100 hover:text-slate-700 dark:text-slate-400 dark:hover:bg-slate-600 dark:hover:text-white;

  &.active {
    @apply bg-purple-100 dark:bg-slate-600 text-slate-700 dark:text-white ring-1 ring-purple-600;
  }
}

.itemIcon {
  @apply w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 select-none transition-colors duration-100 cursor-pointer;
}

.menu-item {
  @apply text-sm px-3 py-2 rounded-md font-sans text-gray-900;

  &.active {
    @apply bg-gray-100 text-gray-800;
  }
}

.user-session-popover {
  min-width: 270px;
  @apply divide-y divide-gray-100 dark:divide-white/10 bg-white dark:bg-gray-700 p-4 rounded-md shadow-lg;

  .submenu-item {
    @apply flex items-center relative py-2 px-3 rounded-sm text-slate-900 dark:text-white;

    &:hover .not-implemented {
      @apply opacity-100;
    }
  }

  .submenu-item-fix {
    @apply flex items-center relative py-2 px-3 rounded-sm text-slate-900 dark:text-white;
  }

  .active {
    @apply text-red-700 bg-amber-300;
  }

  .allowed:hover {
    @apply bg-gray-200 dark:bg-gray-600 rounded-md;
  }

  .not-allowed {
    cursor: not-allowed;
  }
}

.notification-list {
  max-height: calc(100vh - 5rem);
}
