.avatar-container {
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &.is-active {
    z-index: 1;
    @apply ring-2 ring-indigo-500 ring-offset-2;
  }
}

.avatar-bg-squared {
  background-size: contain;
}

.avatar-bg-rounded {
  background-size: cover;
}

.avatar-online {
  border: 2px solid #7de749 !important;
}

.avatar-offline {
  border: 2px solid #8E8E92 !important;
}

.status {
  @apply bottom-[-8px] right-[-8px] w-[20px] h-[20px];
}
