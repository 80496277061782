.cal-toolbar {
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  justify-content: center;
}

.calendar {
  @apply h-full min-h-[650px];

  .fc {
    @apply h-full;
  }

  .fc-view,
  .fc-timeGridWeek-view {
    border-left-width: 0 !important;
    @apply border-l-0 #{!important};
  }

  .fc-col-header-cell-cushion {
    @apply capitalize;
  }

  .fc-timegrid-axis-cushion,
  .fc-timegrid-slot-label {
    @apply dark:text-white;
  }

  .fc-event {
    border-radius: 7px;
  }

  .fc-timegrid-axis-cushion {
    text-transform: capitalize;
  }

  .fc-timegrid-slot {
    height: 40px !important
  }

  /* Targeting odd tr elements within tbody of the FullCalendar time grid */
  .fc-timegrid-slots tr:nth-child(even) .fc-timegrid-slot-label {
    border-top-width: 0;
  }

  .fc-timegrid-slots tr:nth-child(even) .fc-timegrid-slot-lane {
    border-top-width: 0;
  }

  .fc .fc-daygrid-day.fc-day-today,
  .fc .fc-timegrid-col.fc-day-today {
    @apply bg-slate-50 dark:bg-slate-900;
  }

  .fc-event-inner {
    display: flex;
    align-items: center;
  }

  .fc-theme-standard,
  .fc-scrollgrid {
    @apply border-none #{!important};
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    @apply border-gray-200 dark:border-gray-600 #{!important};

    &:last-child {
      border-right: 0;
    }
  }

  .fc .fc-col-header-cell-cushion {
    @apply text-gray-900 dark:text-gray-100 py-3 #{!important};
  }

  .fc-col-header-cell {
    border-width: 0 0 1px;
  }

  .fc .fc-daygrid-day-number {
    @apply font-sans p-2 #{!important};
  }

  .fc-view {
    @apply border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 #{!important};
  }

  .fc-daygrid-dot-event .fc-event-title {
    @apply text-gray-900 dark:text-gray-100 #{!important};
  }

  .fc .fc-button {
    @apply rounded-md py-2;
  }

  .fc .fc-toolbar {
    @apply lg:flex-row flex-col items-start lg:items-center gap-2 #{!important};

    .fc-toolbar-chunk {
      .fc-button-group {
        align-items: center !important;
        justify-content: center !important;

        button {
          display: flex !important;
        }
      }

      .fc-toolbar-title {
        text-transform: capitalize !important;
      }
    }
  }

  .fc .fc-button-primary {
    @apply bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100 font-sans #{!important};

    &:focus {
      @apply shadow-none #{!important};
    }

    &:hover {
      @apply hover:bg-gray-50 dark:hover:bg-gray-600 hover:border-gray-300 dark:hover:border-gray-700 hover:text-gray-900 hover:dark:text-gray-100 #{!important};
    }

    &:disabled {
      @apply cursor-not-allowed bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100 #{!important};

      &:hover {
        @apply bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100 #{!important};
      }
    }
  }

  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    @apply shadow-none #{!important};
  }

  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    @apply bg-gray-100 border border-gray-300 dark:bg-gray-500 dark:border-gray-500 text-gray-600 dark:text-gray-100 #{!important};
  }

  .fc {
    .fc-dayGridMonth-button,
    .fc-timeGridDay-button,
    .fc-timeGridWeek-button,
    .fc-today-button {
      @apply px-8 capitalize #{!important};
    }
  }

  .fc .fc-highlight {
    @apply bg-transparent #{!important};
  }

  .fc-daygrid-dot-event:hover,
  .fc-daygrid-dot-event.fc-event-mirror {
    @apply bg-transparent #{!important};
  }

  .fc .fc-daygrid-event {
    @apply p-0 rounded-md mb-2 #{!important};
  }

  .fc-h-event {
    @apply rounded #{!important};
  }

  //.fc-event-selected,
  //.fc-event:focus {
  //  @apply shadow-none #{!important};
  //
  //  &:after {
  //    @apply bg-transparent #{!important};
  //  }
  //}

  .fc .fc-cell-shaded,
  .fc .fc-day-disabled {
    @apply bg-gray-200 dark:bg-gray-500 #{!important};
  }

  .fc-daygrid-bg-harness {
    .fc-highlight {
      @apply bg-gray-100 dark:bg-gray-700 #{!important};
    }
  }

  .fc-daygrid-event {
    @apply text-xs #{!important};
  }

  .fc .custom-calendar-event {
    @apply flex items-center min-h-[28px] p-1.5 rounded-md w-full overflow-hidden text-ellipsis #{!important};
  }

  .fc-v-event {
    //@apply shadow-blue-600;
  }

  .fc .fc-scrollgrid-section table {
    @apply w-full #{!important};
  }

  .fc .fc-daygrid-body {
    @apply w-full #{!important};
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    @apply mx-0 #{!important};
  }

  .fc-toolbar-chunk {
    @apply flex flex-col sm:flex-row gap-2 w-full sm:w-auto #{!important};
  }


  /**
   * MULTI MONTH VIEW
   *
   */
  .fc-view-harness {
    .fc-multiMonthYear-view {
      @apply border-gray-200 dark:border-white/10 #{!important};

      .fc-multimonth-month {
        .fc-multimonth-header {
          .fc-multimonth-title {
            @apply text-gray-800 dark:text-white font-sans capitalize #{!important};
          }
        }
        .fc-multimonth-daygrid {
          @apply dark:bg-gray-800 #{!important};

          .fc-multimonth-daygrid-table {
            td.fc-day-disabled {
              @apply bg-gray-100 dark:bg-gray-700 #{!important};
            }

            td.fc-day-past,
            td.fc-day-future {
              @apply text-gray-500 dark:text-gray-500 #{!important};

              .fc-daygrid-day-frame {
                .fc-daygrid-day-events {
                  bottom: -7px !important;

                  .fc-daygrid-day-bottom {
                    a {
                      @apply bg-indigo-50 border-indigo-100 text-indigo-700 dark:text-white dark:border-indigo-700 dark:bg-indigo-700 #{!important};
                    }
                  }
                }
              }
            }

            td.fc-day-today {
              @apply bg-indigo-100 dark:bg-indigo-600 dark:text-white #{!important};
            }
          }
        }
      }
    }
  }
}
