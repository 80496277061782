.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}

[data-color-scheme="dark"] {
  color-scheme: dark;
}

[data-color-scheme="light"] {
  color-scheme: light;
}

as-project {
  width: 100%;
}
