.nav-bar-aside-secondary {
  will-change: width;
  transition: width 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
  @apply hidden md:fixed md:inset-y-0 md:flex md:flex-col h-full relative;

  .nav-bar-aside-secondary-container {
    @apply flex flex-grow flex-col overflow-y-auto lg:border-r lg:border-gray-200 dark:lg:border-white/20 bg-gray-100 dark:bg-gray-900 pt-5;

    .area-logo {
      @apply flex flex-shrink-0 items-center px-4;
    }

    .vertical-items {
      @apply mt-5 flex flex-1 flex-col;

      .vertical-items-wrapper {
        @apply flex-1 space-y-1 px-3 pb-4;

        .item-title {
          will-change: visibility;
          transition: visibility 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
          @apply bg-gray-100 text-gray-900 font-sans flex items-center px-2 py-3 text-sm rounded-md;
        }
      }
    }
  }
}

.item-nav {
  @apply text-slate-700 hover:text-gray-900 hover:bg-gray-200 dark:text-slate-400 dark:hover:text-slate-100 dark:hover:bg-slate-700 flex items-center px-2 py-2 text-sm rounded-md;

  &.active {
    @apply bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white;
  }
}
