.footer-container {
  @apply shadow bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-white/20;

  .footer-wrapper {
    @apply mx-auto max-w-full py-3 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8;

    .footer-r-wrapper {
      @apply flex justify-center space-x-6 md:order-2;

      .nav-item {
        @apply text-xs text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;

        &.disabled {
          cursor: not-allowed !important;
        }
      }
    }

    .footer-l-wrapper {
      @apply flex justify-center mt-8 space-x-6 md:order-1 md:mt-0;

      .copyright {
        @apply text-center text-xs text-slate-500 dark:text-slate-400;
      }

      .nav-item {
        @apply text-xs text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;
      }
    }
  }
}
