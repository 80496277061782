@import 'ng-zorro-antd/style/index.min.css';
@import 'ng-zorro-antd/tooltip/style/index.min.css';
@import 'ng-zorro-antd/spin/style/index.min.css';
@import 'ng-zorro-antd/modal/style/index.min.css';
@import 'ng-zorro-antd/table/style/index.min.css';
@import 'ng-zorro-antd/dropdown/style/index.min.css';
@import 'ng-zorro-antd/select/style/index.min.css';
@import 'ng-zorro-antd/drawer/style/index.min.css';
@import 'ng-zorro-antd/notification/style/index.min.css';
@import 'ng-zorro-antd/popover/style/index.min.css';
@import 'ng-zorro-antd/transfer/style/index.min.css';
@import 'ng-zorro-antd/badge/style/index.min.css';
@import 'ng-zorro-antd/tabs/style/index.min.css';
@import 'ng-zorro-antd/form/style/index.min.css';
@import 'ng-zorro-antd/input/style/index.min.css';
@import 'ng-zorro-antd/avatar/style/index.min.css';
@import 'ng-zorro-antd/upload/style/index.min.css';
@import 'ng-zorro-antd/pagination/style/index.min.css';
@import 'ng-zorro-antd/list/style/index.min.css';
@import 'ng-zorro-antd/skeleton/style/index.min.css';
@import 'ng-zorro-antd/typography/style/index.min.css';
@import 'ng-zorro-antd/radio/style/index.min.css';
@import 'ng-zorro-antd/switch/style/index.min.css';
@import 'ng-zorro-antd/auto-complete/style/index.min.css';
@import 'ng-zorro-antd/empty/style/index.min.css';
@import 'ng-zorro-antd/steps/style/index.min.css';
@import 'ng-zorro-antd/timeline/style/index.min.css';
@import 'ng-zorro-antd/date-picker/style/index.min.css';
@import 'ng-zorro-antd/tag/style/index.min.css';
@import 'ng-zorro-antd/checkbox/style/index.min.css';
@import 'ng-zorro-antd/comment/style/index.min.css';
@import 'ng-zorro-antd/message/style/index.min.css';
@import 'ng-zorro-antd/alert/style/index.min.css';
@import 'ng-zorro-antd/menu/style/index.min.css';
@import 'ng-zorro-antd/progress/style/index.min.css';
@import 'ng-zorro-antd/collapse/style/index.min.css';
@import 'ng-zorro-antd/segmented/style/index.min.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "libs/styles/src/lib/index";
