.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  @apply bg-gray-100 dark:bg-slate-700;
}

.ant-segmented {
  @apply rounded-md bg-gray-100 dark:bg-slate-700;

  .ant-segmented-thumb {
    //top: 0.25rem !important;
    //height: calc(100% - 0.5rem) !important;
    height: 100%;
    @apply bg-gray-100 dark:text-white dark:bg-gray-800 rounded-md #{!important};
  }

  .ant-segmented-group {
    padding: 0 !important;
    height: 2.4rem;

    @apply rounded-md dark:bg-slate-700 p-1;

    label {
      display: flex;
      align-items: center;
      position: relative !important;

      &.ant-segmented-item-selected {
        @apply dark:text-white dark:bg-gray-800 rounded-md #{!important};

        a {
          @apply dark:text-white;
        }
      }

      .ant-segmented-item-label {
        display: flex;
        align-items: center;
        @apply dark:text-slate-300;

        .segment-link {
          @apply inline-flex items-center px-1 text-sm text-slate-700 dark:text-slate-50 hover:text-purple-500 dark:hover:text-purple-300 #{!important};

          .segment-link-backdrop {
            min-width: 37px;
            @apply absolute top-0 bottom-0 right-0 bg-gray-100/70 dark:bg-gray-900/80 backdrop-opacity-10 backdrop-invert rounded-tr-md rounded-br-md;
          }

          .segment-link-option {
            align-items: center;
            @apply mx-1.5 absolute top-0 bottom-0 right-0 #{!important};
          }
        }
      }
    }
  }
}
