$background: #f4f5f7;
$blue-border: #4c9aff;
$gray-border: #d1d5db;
$purple-border: #9333ea;
$purple-border-shadow: #c084fc;

.base-form {
  max-width: 640px;
}

form {
  .label {
    display: block;
    padding-bottom: 0.5rem;
    @apply text-sm font-sans text-gray-700 dark:text-white;
  }

  label.required:after {
    content: '*';
    color: #e11d48;
  }

  .label-no-p {
    font-weight: 500;
    font-size: 0.8125rem;
    display: block;
    @apply text-sm font-sans text-gray-700 dark:text-white;
  }

  .form-input {
    height: 100%;
    min-height: 2.5rem;
    width: 100%;
    padding: 0.325rem 0.75rem;
    transition: background 0.1s;
    @apply bg-white border border-gray-200 rounded-md shadow-sm text-sm dark:bg-transparent dark:border-gray-600 dark:text-white;

    &:hover {
      @apply bg-gray-100 dark:bg-transparent;
    }

    &:disabled {
      @apply bg-gray-100 cursor-not-allowed;
    }

    &:focus {
      @apply bg-white border-purple-600 ring-purple-600 dark:bg-transparent;
    }

    &::placeholder {
      font-size: 14.5px;
      vertical-align: middle;
    }
  }

  input:not(:disabled),
  text-area:not(:disabled) {
    cursor: text;
  }

  .ng-invalid {
    &.ng-dirty,
    &.ng-dirty:focus,
    &.ng-touched,
    &.ng-touched:focus {
      border: none;
      box-shadow: none;
      @apply bg-white dark:bg-transparent rounded-md ring-2 ring-red-600;
    }
  }

  .form-select {
    @apply shadow-sm;
  }

  nz-switch {
    .ant-switch-checked {
      @apply bg-purple-600 #{!important};
    }
  }
}

//text-area
.textarea-inline-control {
  border-color: transparent;
  padding: 7px 7px 8px;
  line-height: 1.28;
  resize: none;
  box-shadow: transparent 0 0 0 1px;
  transition: background 0.1s ease 0s;
  overflow: auto;
  overflow-y: hidden;
  width: 100%;
  border-radius: 3px;
  appearance: none;

  @apply w-full h-auto text-gray-900;

  &:hover:not(:focus) {
    @apply bg-gray-50;
  }

  &:focus {
    @apply bg-white;
    border: 1px solid $purple-border;
    box-shadow: 0 0 0 1px $purple-border-shadow;
    outline: none;
  }
}

// input group
.ant-input-group-addon {
  @apply rounded-tl-md rounded-bl-md min-w-[8rem] #{!important};

  nz-select:hover {
    background: transparent !important;
  }
}

.form-check {
  .form-check-input {
    @apply rounded text-purple-600 focus:outline-none focus:ring-0;
  }

  input[type=radio],
  input[type=checkbox] {
    @apply bg-white;
  }

  [type='checkbox']:checked,
  [type='radio']:checked {
    @apply bg-purple-600;
  }

  .form-check-label {
    @apply font-sans text-gray-700 dark:text-white ml-2;
  }
}
