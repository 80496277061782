.ant-table {
  @apply bg-white dark:bg-gray-800;

  .ant-table-content {
    @apply border border-l-0 border-r-0 border-gray-200 dark:border-white/10;

    .ant-table-thead {
      tr {
        th {
          @apply bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-white/10 text-slate-900 dark:text-white;
        }
      }
    }

    .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
    .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
    .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
    .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
      background: unset; //Change the existing color to `unset`
    }

    .ant-table-tbody > tr > td {
      @apply border-gray-200 dark:border-white/10;
    }

    .ant-table-tbody {
      tr.ant-table-row {
        @apply hover:bg-gray-50 hover:dark:bg-gray-700 text-gray-600 dark:text-slate-300 hover:text-gray-900 dark:hover:text-white;

        &:hover {
          td {
            @apply text-gray-800 dark:text-white cursor-pointer;

            &.ant-table-cell-fix-left,
            &.ant-table-cell-fix-right {
              @apply dark:bg-gray-700 dark:text-white;
            }

            span {
              @apply text-gray-800 dark:text-white;
            }
          }
        }
        td {
          @apply border-gray-200 dark:border-white/10;

          &.ant-table-cell-fix-left,
          &.ant-table-cell-fix-right {
            @apply dark:bg-gray-800;
          }

        }

        &.active {
          @apply bg-slate-100/90 dark:bg-slate-600/80;

          //a {
          //  @apply hover:text-white;
          //}

          td {
            // text-white dark:text-white
            @apply border-slate-200 dark:border-slate-600 #{!important};

            //span {
            //  @apply text-white dark:text-white;
            //}
          }
        }
      }
    }
  }

  nz-table-inner-scroll {
    @apply border border-l-0 border-r-0 border-gray-200 dark:border-white/10;

    .ant-table-thead {
      tr {
        position: relative;
        th {
          @apply bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-white/10 text-slate-900 dark:text-white;

          &:hover {
            min-width: 155px;
            width: 100%;
            max-width: 250px;
            @apply relative bg-gray-200 dark:bg-gray-700 border-transparent shadow;

            &.ant-table-cell-fix-left,
            &.ant-table-cell-fix-right {
              white-space: nowrap !important;
              min-width: 45px !important;
              @apply dark:bg-gray-700 dark:text-white inline-block;
            }
          }

        }
      }
    }

    .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
    .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
    .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
    .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
      background: unset; //Change the existing color to `unset`
    }

    .ant-table-tbody > tr > td {
      @apply border-gray-200 dark:border-white/10;
    }

    .ant-table-tbody {
      tr.ant-table-row {
        @apply font-sans hover:bg-gray-50 hover:dark:bg-gray-700 text-gray-600 dark:text-slate-300 hover:text-gray-900 dark:hover:text-white;

        &:hover {
          td {
            @apply text-gray-800 dark:text-white cursor-pointer;

            &.ant-table-cell-fix-left,
            &.ant-table-cell-fix-right {
              @apply bg-white dark:bg-gray-700 dark:text-white;
            }

            span {
              @apply text-gray-800 dark:text-white;
            }
          }
        }
        td {
          @apply border-gray-200 dark:border-white/10;

          &.ant-table-cell-fix-left,
          &.ant-table-cell-fix-right {
            @apply dark:bg-gray-800;
          }

        }

        &.active {
          @apply bg-purple-800/90 dark:bg-purple-700/70;

          a {
            @apply hover:text-white;
          }
          td {
            @apply text-white dark:text-white border-purple-400 dark:border-white/10 #{!important};
            span {
              @apply text-white dark:text-white;
            }
          }
        }
      }
    }

  }

  .ant-table-footer {
    @apply bg-white dark:bg-gray-800 border-gray-200 dark:border-white/10 text-slate-900 dark:text-white;
  }
}

.ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//.ant-table.ant-table-small .ant-table-footer,
//.ant-table.ant-table-small .ant-table-tbody > tr > td,
//.ant-table.ant-table-small .ant-table-thead > tr > th,
//.ant-table.ant-table-small .ant-table-title,
//.ant-table.ant-table-small tfoot > tr > td,
//.ant-table.ant-table-small tfoot > tr > th {
//  padding: 5px !important;
//}
