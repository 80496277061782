@media (prefers-color-scheme: dark) {
  .ant-table .ant-table-footer {
    --tw-text-opacity: 1;
    color: transparent !important;
  }
}

@media (prefers-color-scheme: light) {
  .ant-table .ant-table-footer {
    --tw-text-opacity: 1;
    color: transparent !important;
  }
}

.tag {
  @apply
  py-1
  px-2.5
  inline-flex
  items-center
  border
  border-gray-200
  dark:border-white/20
  text-xs
  font-sans;

  &.radius-none {
    @apply rounded-none
  }

  &.radius-sm {
    @apply rounded-sm;
  }

  &.radius-md {
    @apply rounded-md;
  }

  &.radius-full {
    @apply rounded-full;
  }

  &.tag-sm {
    @apply py-0.5 px-1;
  }

  &.tag-lg {
    @apply py-2 px-3.5;
  }

  &.outline {
    outline-style: none;
    .tag-inner {
      @apply text-slate-700 dark:text-white #{!important};
    }
  }

  &.outline,
  &.info {
    .tag-affix, .tag-suffix {
      @apply bg-indigo-600;
    }
  }

  &.outline {
    &.default {
      .tag-affix, .tag-suffix {
        @apply bg-gray-600 dark:bg-slate-600;
      }
    }

    &.danger {
      .tag-affix, .tag-suffix {
        @apply bg-red-600 dark:bg-red-300;
      }
    }

    &.warning {
      .tag-affix, .tag-suffix {
        @apply bg-amber-600 dark:bg-amber-300;
      }
    }

    &.success {
      .tag-affix, .tag-suffix {
        @apply bg-emerald-600 dark:bg-emerald-300;
      }
    }

  }

  &.solid {
    &.default {
      @apply bg-gray-100 text-gray-800 dark:bg-slate-700 dark:text-gray-300 border-none #{!important};
    }

    &.danger {
      @apply text-red-600 bg-red-100 dark:text-red-100 dark:bg-red-500/20 border-none #{!important};
    }

    &.warning {
      @apply bg-amber-100 text-amber-600 dark:bg-amber-500/20 dark:text-amber-100 border-none #{!important};
    }

    &.success {
      @apply bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 border-none #{!important};
    }

    &.info {
      @apply bg-purple-100 text-purple-600 dark:bg-purple-500/20 dark:text-purple-100 border-none #{!important};
    }
  }

  .tag-inner {
    @apply flex items-center;
  }
}

.tag-affix {
  @apply h-2 w-2 rounded-full bg-gray-300;
}

.tag-prefix {
  @apply mr-1.5;
}

.tag-suffix {
  @apply ml-1.5;
}
