.loader-wrapper {
  //background-image: linear-gradient(to bottom right, #463982, #1B172F);
  @apply w-full h-screen bg-gradient-to-bl from-[#F2F1F1] to-[#F9F9F6] dark:from-[#463982] dark:to-[#1B172F];

  .loader-logo {
    position: absolute;
    top: 10%;
    left: 15%;
    z-index: 99;
  }

  .loader-inner {
    width: 700px;
    left: calc(50% - 350px);
    @apply absolute top-1/2 z-50;
  }
}

.cursor {
  border-right-style: solid;
  animation: blink 1s infinite;
}

@keyframes cursor {
  to {
    opacity: 0;
  }
}
