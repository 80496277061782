#dropzone {
  position: fixed;
  bottom: 0;
  right: 0;
  //background-color: rgba(0, 99, 246, 0.2);
  //border: 2px dashed #0063F6 !important;
  opacity: 0;
  z-index: 4;
  transition: opacity 175ms;
  @apply bg-purple-800/20 border-2 border-dashed border-purple-500;

  .dropzone-info {
    position: absolute;
    bottom: 50px;
    left: calc(50% - 130px);
    width: 260px;
    height: fit-content;
    border-radius: 6px;
    opacity: 1;
    padding: 20px;
    transition: visibility 175ms, opacity 175ms;
    box-shadow: rgb(0 0 0 / 10%) 0 1px 2px 0, rgb(0 0 0 / 10%) 0 1px 4px 0, rgb(0 0 0 / 10%) 0 2px 8px 0;
    @apply bg-white text-black dark:bg-slate-900 dark:text-white;

    p {
      text-align: center;
      font-weight: 600;
    }
  }
}
