nz-switch {
  button {
    max-width: 38px;
    @apply
    bg-gray-200
    dark:bg-gray-600
    cursor-pointer
    transition-colors
    ease-in-out
    duration-200 #{!important};

    &.ant-switch-checked {
      @apply bg-purple-600 #{!important};
    }

    &.ant-switch-disabled {
      @apply opacity-50 cursor-not-allowed #{!important};
    }
  }
}
