h1 {
  @apply text-2xl font-display text-slate-900 dark:text-white;
}

h2 {
  @apply text-xl font-display text-slate-900 dark:text-white;
}

h3 {
  @apply font-display text-slate-900 dark:text-white;
}

p {
  @apply text-sm font-sans text-slate-500 dark:text-slate-400;
}

label {
  @apply font-sans text-slate-700 dark:text-slate-50;
}

small {
  @apply text-slate-500 dark:text-slate-400;
}

em {
  @apply text-sm text-slate-300 dark:text-slate-600;
}

a {
  @apply font-sans text-purple-600 dark:text-purple-300 hover:text-purple-500 dark:hover:text-purple-500;
}

.animate-text {
  font-weight: normal;
  @apply text-3xl font-display text-slate-900 dark:text-white;
}
