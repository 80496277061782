$gray-border: #d1d5db;
$purple-border: #9333ea;
$purple-border-shadow: #c084fc;

nz-date-picker,
nz-time-picker {
  @apply h-10 rounded-md border-gray-300 hover:bg-gray-100 hover:text-gray-800 shadow-sm dark:bg-transparent dark:border-gray-600 #{!important};

  &:hover {
    border-color: inherit !important;
  }

  &.ant-picker-focused {
    background: #fff;
    border: 1px solid $purple-border !important;
    box-shadow: 0 0 0 1px $purple-border-shadow !important;
    outline: none !important;
  }

  input {
    font-size: 14.5px !important;
  }
}
