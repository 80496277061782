.search-list {
  @apply flex items-center px-6 mx-1.5 md:mx-auto lg:mx-0 xl:px-0 max-w-md;

  .icon {
    @apply pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3;

    svg-icon {
      @apply dark:text-slate-400;
    }
  }

  input {
    height: 100%;
    min-height: 2.5rem;
    width: 100%;
    transition: background 0.1s;
    @apply py-2 pl-10 pr-3 bg-white border border-gray-200 rounded-md shadow-sm text-sm dark:text-slate-400 dark:border-slate-600 dark:bg-gray-800;

    &:hover {
      @apply bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-slate-200;
    }

    &:disabled {
      @apply bg-gray-100 dark:bg-gray-600 cursor-not-allowed;
    }

    &:focus {
      @apply bg-white dark:text-white dark:bg-gray-800 border-purple-600 ring-purple-600;
    }

    &::placeholder {
      font-size: 14.5px;
      vertical-align: middle;
    }
  }

  .input-clear-icon {
    position: absolute;
    top: 0.5rem;
    right: 5px;
    z-index: 99;
  }
}
