.notification-detail {
  p {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  ul {
    list-style: circle;
    padding: 15px;
  }
}

.ant-notification-notice {
  @apply pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5;

  .ant-notification-notice-message {
    @apply text-sm font-sans text-gray-900 dark:text-white;
  }

  .ant-notification-notice-description {
    @apply mt-1 text-sm text-gray-500 dark:text-gray-400;
  }
}
