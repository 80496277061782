.ant-pagination-prev,
.ant-pagination-next {
  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    @apply text-gray-500 dark:text-gray-400;
    &:hover {
      @apply text-purple-500 dark:text-purple-400;
    }
  }
  &:hover {
    .ant-pagination-item-link {
      @apply text-purple-500 dark:text-purple-400;
    }
  }
}

.ant-pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply bg-transparent rounded border-none;

  &.ant-pagination-item-active {
    @apply
    bg-purple-50
    dark:bg-purple-500
    dark:bg-opacity-20
    hover:bg-purple-100
    dark:hover:bg-purple-500
    dark:hover:bg-opacity-30
    active:bg-purple-200
    dark:active:bg-purple-500
    dark:active:bg-opacity-40
    text-purple-600
    dark:text-purple-50;

    a {
      @apply text-purple-600 dark:text-purple-50;
    }
  }

  a {
    @apply text-gray-500 dark:text-gray-400;
    &:hover {
      @apply text-purple-500 dark:text-purple-400;
    }
  }

  &:hover {
    a {
      @apply text-purple-500 dark:text-purple-400;
    }
  }
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  .ant-pagination-item-link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-pagination-item-container {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        @apply text-purple-500 dark:text-purple-400;
      }

      .ant-pagination-item-ellipsis {
        display: flex;
        justify-content: center;
        align-items: center;
        @apply text-gray-500 dark:text-gray-400;
      }
    }
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  justify-content: center;
}

.ant-pagination-options {
  height: 24px;

  nz-select {
    height: 24px;

    nz-select-top-control {
      min-height: 24px;
      height: 24px;

      nz-select-search {
        input {
          font-size: 12px;
        }
      }

      nz-select-item {
        font-size: 12px;
      }
    }
  }
}
