@import 'quill/dist/quill.snow.css';
@import 'swiper/swiper-bundle.css';
@media (prefers-color-scheme: dark) {
  .ant-table .ant-table-footer {
    --tw-text-opacity: 1;
    color: transparent !important;
  }
}
@media (prefers-color-scheme: light) {
  .ant-table .ant-table-footer {
    --tw-text-opacity: 1;
    color: transparent !important;
  }
}
.tag {
  @apply py-1
  px-2.5
  inline-flex
  items-center
  border
  border-gray-200
  dark:border-white/20
  text-xs
  font-sans;
}
.tag.radius-none {
  @apply rounded-none;
}
.tag.radius-sm {
  @apply rounded-sm;
}
.tag.radius-md {
  @apply rounded-md;
}
.tag.radius-full {
  @apply rounded-full;
}
.tag.tag-sm {
  @apply py-0.5 px-1;
}
.tag.tag-lg {
  @apply py-2 px-3.5;
}
.tag.outline {
  outline-style: none;
}
.tag.outline .tag-inner {
  @apply text-slate-700 dark:text-white !important;
}
.tag.outline .tag-affix, .tag.outline .tag-suffix, .tag.info .tag-affix, .tag.info .tag-suffix {
  @apply bg-indigo-600;
}
.tag.outline.default .tag-affix, .tag.outline.default .tag-suffix {
  @apply bg-gray-600 dark:bg-slate-600;
}
.tag.outline.danger .tag-affix, .tag.outline.danger .tag-suffix {
  @apply bg-red-600 dark:bg-red-300;
}
.tag.outline.warning .tag-affix, .tag.outline.warning .tag-suffix {
  @apply bg-amber-600 dark:bg-amber-300;
}
.tag.outline.success .tag-affix, .tag.outline.success .tag-suffix {
  @apply bg-emerald-600 dark:bg-emerald-300;
}
.tag.solid.default {
  @apply bg-gray-100 text-gray-800 dark:bg-slate-700 dark:text-gray-300 border-none !important;
}
.tag.solid.danger {
  @apply text-red-600 bg-red-100 dark:text-red-100 dark:bg-red-500/20 border-none !important;
}
.tag.solid.warning {
  @apply bg-amber-100 text-amber-600 dark:bg-amber-500/20 dark:text-amber-100 border-none !important;
}
.tag.solid.success {
  @apply bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 border-none !important;
}
.tag.solid.info {
  @apply bg-purple-100 text-purple-600 dark:bg-purple-500/20 dark:text-purple-100 border-none !important;
}
.tag .tag-inner {
  @apply flex items-center;
}

.tag-affix {
  @apply h-2 w-2 rounded-full bg-gray-300;
}

.tag-prefix {
  @apply mr-1.5;
}

.tag-suffix {
  @apply ml-1.5;
}

.card {
  @apply rounded flex;
}
.card .card-color {
  @apply flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-sans rounded-l-md;
}
.card .card-content {
  @apply flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:text-white dark:border-gray-600 bg-white dark:bg-slate-700 rounded-r-md;
}

.card-border {
  @apply border border-gray-200 dark:border-gray-600 !important;
}

.card-shadow {
  @apply shadow !important;
}

.card-header-border {
  @apply border-b border-gray-200 dark:border-gray-600;
}

.card-header-extra {
  @apply flex justify-between items-center;
}

.card-footer {
  @apply rounded-bl-lg rounded-br-lg;
}

.card-footer-border {
  @apply border-t border-gray-200 dark:border-gray-600;
}

.card-footer-right {
  @apply flex justify-end;
}

.card-header,
.card-footer {
  @apply py-3 px-5 flex flex-col;
}

.card-body {
  @apply p-5 flex flex-col flex-1;
}

.card-gutterless {
  @apply p-0;
}

.tags {
  @apply inline-flex items-center rounded-full bg-purple-100 px-2.5 py-0.5 text-xs font-sans text-purple-800;
}

.button {
  @apply font-sans focus:outline-none whitespace-nowrap w-full flex items-center justify-center;
}
.button.radius-round {
  @apply rounded-md;
}
.button.radius-circle {
  @apply rounded-full;
}
.button.radius-none {
  @apply rounded-none;
}
.button.in-group {
  @apply rounded-tl-none rounded-bl-none border border-gray-200 dark:border-gray-600 border-l-0 !important;
}
.button.default {
  @apply bg-gray-50 dark:bg-slate-700 hover:bg-gray-100 dark:hover:bg-slate-600 active:bg-gray-100 dark:active:bg-gray-500 text-gray-600 dark:text-gray-100;
}
.button.default svg-icon {
  @apply text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;
}
.button.solid {
  @apply bg-purple-600 hover:bg-purple-500 active:bg-purple-700 text-white;
}
.button.solid.danger {
  @apply bg-rose-600 hover:bg-rose-500 active:bg-rose-700 text-white !important;
}
.button.solid.warning {
  @apply bg-orange-500 hover:bg-orange-400 active:bg-orange-600 text-white !important;
}
.button.solid.success {
  @apply bg-emerald-500 hover:bg-emerald-400 active:bg-emerald-600 text-white !important;
}
.button.solid.info {
  @apply bg-sky-500 hover:bg-sky-400 active:bg-sky-600 text-white !important;
}
.button.twotone {
  @apply bg-purple-50 dark:bg-purple-500 dark:bg-opacity-20 hover:bg-purple-100 dark:hover:bg-purple-500 dark:hover:bg-opacity-30 active:bg-purple-200 dark:active:bg-purple-500 dark:active:bg-opacity-40 text-purple-600 dark:text-purple-50;
}
.button.twotone.danger {
  @apply bg-rose-50 dark:bg-rose-500 dark:bg-opacity-20 hover:bg-rose-100 dark:hover:bg-rose-500 dark:hover:bg-opacity-30 active:bg-rose-200 dark:active:bg-rose-500 dark:active:bg-opacity-40 text-rose-600 dark:text-rose-50 !important;
}
.button.twotone.warning {
  @apply bg-orange-50 dark:bg-orange-500 dark:bg-opacity-20 hover:bg-orange-100 dark:hover:bg-orange-500 dark:hover:bg-opacity-30 active:bg-orange-200 dark:active:bg-orange-500 dark:active:bg-opacity-40 text-orange-600 dark:text-orange-50 !important;
}
.button.twotone.success {
  @apply bg-emerald-50 dark:bg-emerald-500 dark:bg-opacity-20 hover:bg-emerald-100 dark:hover:bg-emerald-500 dark:hover:bg-opacity-30 active:bg-emerald-200 dark:active:bg-emerald-500 dark:active:bg-opacity-40 text-emerald-600 dark:text-emerald-50 !important;
}
.button.twotone.info {
  @apply bg-sky-50 dark:bg-sky-500 dark:bg-opacity-20 hover:bg-sky-100 dark:hover:bg-sky-500 dark:hover:bg-opacity-30 active:bg-sky-200 dark:active:bg-sky-500 dark:active:bg-opacity-40 text-sky-800 dark:text-sky-50 !important;
}
.button.plain {
  @apply bg-transparent border border-transparent hover:bg-gray-50 dark:hover:bg-slate-700 active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500 text-gray-600 dark:text-gray-100 dark:hover:text-white;
}
.button.plain.menu-icon {
  @apply bg-red-600 p-2 hover:bg-red-600 border-none focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600 !important;
}
.button.plain svg-icon {
  @apply text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;
}
.button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.button-upload {
  @apply rounded-md;
}
.button-upload.upload {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @apply h-10 align-middle leading-none whitespace-nowrap transition-all duration-100 appearance-none cursor-pointer select-none px-3;
}
.button-upload.upload .upload-label {
  @apply flex items-center pointer-events-none relative font-sans;
}
.button-upload.upload .upload-label svg-icon {
  @apply text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;
}
.button-upload.upload .upload-file-input {
  @apply absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0;
}
.button-upload.upload-input {
  @apply bg-transparent border border-transparent hover:bg-gray-50 dark:hover:bg-slate-600 active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500 text-gray-600 dark:text-gray-100;
}

.ant-table {
  @apply bg-white dark:bg-gray-800;
}
.ant-table .ant-table-content {
  @apply border border-l-0 border-r-0 border-gray-200 dark:border-white/10;
}
.ant-table .ant-table-content .ant-table-thead tr th {
  @apply bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-white/10 text-slate-900 dark:text-white;
}
.ant-table .ant-table-content .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table .ant-table-content .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table .ant-table-content .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table .ant-table-content .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: unset;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td {
  @apply border-gray-200 dark:border-white/10;
}
.ant-table .ant-table-content .ant-table-tbody tr.ant-table-row {
  @apply hover:bg-gray-50 hover:dark:bg-gray-700 text-gray-600 dark:text-slate-300 hover:text-gray-900 dark:hover:text-white;
}
.ant-table .ant-table-content .ant-table-tbody tr.ant-table-row:hover td {
  @apply text-gray-800 dark:text-white cursor-pointer;
}
.ant-table .ant-table-content .ant-table-tbody tr.ant-table-row:hover td.ant-table-cell-fix-left, .ant-table .ant-table-content .ant-table-tbody tr.ant-table-row:hover td.ant-table-cell-fix-right {
  @apply dark:bg-gray-700 dark:text-white;
}
.ant-table .ant-table-content .ant-table-tbody tr.ant-table-row:hover td span {
  @apply text-gray-800 dark:text-white;
}
.ant-table .ant-table-content .ant-table-tbody tr.ant-table-row td {
  @apply border-gray-200 dark:border-white/10;
}
.ant-table .ant-table-content .ant-table-tbody tr.ant-table-row td.ant-table-cell-fix-left, .ant-table .ant-table-content .ant-table-tbody tr.ant-table-row td.ant-table-cell-fix-right {
  @apply dark:bg-gray-800;
}
.ant-table .ant-table-content .ant-table-tbody tr.ant-table-row.active {
  @apply bg-slate-100/90 dark:bg-slate-600/80;
}
.ant-table .ant-table-content .ant-table-tbody tr.ant-table-row.active td {
  @apply border-slate-200 dark:border-slate-600 !important;
}
.ant-table nz-table-inner-scroll {
  @apply border border-l-0 border-r-0 border-gray-200 dark:border-white/10;
}
.ant-table nz-table-inner-scroll .ant-table-thead tr {
  position: relative;
}
.ant-table nz-table-inner-scroll .ant-table-thead tr th {
  @apply bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-white/10 text-slate-900 dark:text-white;
}
.ant-table nz-table-inner-scroll .ant-table-thead tr th:hover {
  min-width: 155px;
  width: 100%;
  max-width: 250px;
  @apply relative bg-gray-200 dark:bg-gray-700 border-transparent shadow;
}
.ant-table nz-table-inner-scroll .ant-table-thead tr th:hover.ant-table-cell-fix-left, .ant-table nz-table-inner-scroll .ant-table-thead tr th:hover.ant-table-cell-fix-right {
  white-space: nowrap !important;
  min-width: 45px !important;
  @apply dark:bg-gray-700 dark:text-white inline-block;
}
.ant-table nz-table-inner-scroll .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table nz-table-inner-scroll .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table nz-table-inner-scroll .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table nz-table-inner-scroll .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: unset;
}
.ant-table nz-table-inner-scroll .ant-table-tbody > tr > td {
  @apply border-gray-200 dark:border-white/10;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row {
  @apply font-sans hover:bg-gray-50 hover:dark:bg-gray-700 text-gray-600 dark:text-slate-300 hover:text-gray-900 dark:hover:text-white;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row:hover td {
  @apply text-gray-800 dark:text-white cursor-pointer;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row:hover td.ant-table-cell-fix-left, .ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row:hover td.ant-table-cell-fix-right {
  @apply bg-white dark:bg-gray-700 dark:text-white;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row:hover td span {
  @apply text-gray-800 dark:text-white;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row td {
  @apply border-gray-200 dark:border-white/10;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row td.ant-table-cell-fix-left, .ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row td.ant-table-cell-fix-right {
  @apply dark:bg-gray-800;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row.active {
  @apply bg-purple-800/90 dark:bg-purple-700/70;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row.active a {
  @apply hover:text-white;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row.active td {
  @apply text-white dark:text-white border-purple-400 dark:border-white/10 !important;
}
.ant-table nz-table-inner-scroll .ant-table-tbody tr.ant-table-row.active td span {
  @apply text-white dark:text-white;
}
.ant-table .ant-table-footer {
  @apply bg-white dark:bg-gray-800 border-gray-200 dark:border-white/10 text-slate-900 dark:text-white;
}

.ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget-item-wrapper {
  @apply border border-gray-200 dark:border-white/20 rounded-md shadow-sm;
}
.widget-item-wrapper .label {
  @apply truncate text-sm font-sans text-gray-900 dark:text-white;
}
.widget-item-wrapper .sub-title {
  @apply truncate text-xs text-gray-500 dark:text-slate-300;
}
.widget-item-wrapper .item-data {
  @apply mt-2 text-5xl font-sans tracking-tight text-gray-900 dark:text-white;
}

.dropdown {
  @apply relative inline-block;
}
.dropdown .dropdown-count {
  @apply ml-1.5 rounded bg-gray-200 dark:bg-gray-700 py-0.5 px-1.5 text-xs font-sans tabular-nums text-gray-700 dark:text-gray-400;
}
.dropdown .dropdown-arrow {
  @apply flex items-center;
}
.dropdown .dropdown-arrow svg-icon {
  @apply transition ease-in-out duration-150;
}
.dropdown .dropdown-arrow svg-icon.clicked {
  @apply transform rotate-180;
}

.divider {
  @apply pb-0.5 mt-0.5 border-t border-gray-200 dark:border-white/20;
}

.dropdown-menu {
  min-width: 10rem;
  width: max-content;
  @apply rounded-md shadow-lg bg-white dark:bg-gray-700 dark:shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none p-1.5 z-30 !important;
}
.dropdown-menu .dropdown-title {
  @apply px-4
    py-1
    block
    text-sm
    font-sans
    text-gray-700
    dark:text-slate-200
    hover:bg-white
    dark:hover:text-slate-200
    dark:hover:bg-gray-700
    rounded-md;
}
.dropdown-menu .dropdown-item-container {
  @apply max-h-[26rem] h-full overflow-y-auto -mx-1.5;
}
.dropdown-menu .dropdown-item-container .dropdown-item {
  @apply px-4
      py-1
      mx-1.5
      block
      text-sm
      font-sans
      text-gray-700
      hover:cursor-pointer
      dark:text-slate-300
      dark:hover:text-white
      hover:bg-gray-100
      dark:hover:bg-gray-600
      rounded-md;
}
.dropdown-menu .dropdown-item-container .dropdown-item.active {
  @apply bg-gray-100 dark:bg-gray-600;
}
.dropdown-menu .dropdown-item-container .dropdown-item.disabled {
  pointer-events: none;
  cursor: default;
}
.dropdown-menu .dropdown-first-item {
  pointer-events: none;
  cursor: default;
  @apply px-4
    py-1
    block
    text-sm
    font-sans
    text-gray-700
    dark:text-slate-300
    rounded-md;
}
.dropdown-menu .dropdown-item {
  @apply px-4
    py-1
    block
    text-sm
    font-sans
    text-gray-700
    hover:cursor-pointer
    dark:text-slate-300
    dark:hover:text-white
    hover:bg-gray-100
    dark:hover:bg-gray-600
    rounded-md;
}
.dropdown-menu .dropdown-item.active {
  @apply bg-gray-100 dark:bg-gray-600;
}
.dropdown-menu .dropdown-item.disabled {
  pointer-events: none;
  cursor: default;
}
.dropdown-menu.lg .dropdown-item {
  @apply relative flex gap-x-6 rounded-md p-4 hover:bg-gray-50 dark:hover:bg-gray-600;
}
.dropdown-menu.lg .dropdown-item .dropdown-item-icon {
  @apply mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 dark:bg-gray-600;
}
.dropdown-menu.lg .dropdown-item .dropdown-item-icon .drop-icon {
  @apply text-gray-600 dark:text-gray-50;
}
.dropdown-menu.lg .dropdown-item .link {
  @apply text-gray-900 hover:text-indigo-600 dark:text-white dark:hover:text-indigo-400;
}

.ant-dropdown-menu-sub {
  @apply py-0 rounded-lg;
}

nz-transfer-list {
  @apply dark:border-gray-600;
}

.ant-transfer-list-header {
  @apply dark:bg-transparent dark:text-white dark:border-gray-600;
}

.ant-transfer-list-search {
  @apply dark:bg-transparent dark:border-gray-600;
}
.ant-transfer-list-search .ant-input-prefix i {
  @apply dark:text-white;
}
.ant-transfer-list-search .ant-input {
  @apply dark:bg-transparent dark:text-slate-50;
}

.ant-transfer-list-content-item:hover {
  @apply dark:bg-slate-800 dark:text-slate-50;
}

.ant-transfer-operation .ant-btn {
  @apply dark:bg-transparent dark:border-gray-400;
}
.ant-transfer-operation .ant-btn i {
  @apply dark:text-gray-400;
}

.stacked-list-title {
  @apply sm:flex sm:items-center;
}
.stacked-list-title h1 {
  @apply text-base font-sans text-gray-900 dark:text-gray-400;
}

.stacked-list-divider {
  @apply -my-5 divide-y divide-gray-200 dark:divide-white/10;
}

.item {
  @apply flex items-center space-x-4;
}
.item .avatar {
  @apply flex-shrink-0;
}
.item .content {
  @apply min-w-0 flex-1;
}
.item .content .content-title {
  @apply truncate text-sm font-sans text-gray-900 dark:text-white;
}
.item .content .content-subtitle {
  @apply truncate text-xs text-gray-500 dark:text-gray-400;
}
.item .content-subtitle {
  @apply truncate text-xs text-gray-500 dark:text-gray-400;
}

.alert-button {
  @apply mt-2 sm:mt-3 flex;
}
.alert-button .button {
  @apply border bg-white hover:bg-gray-100 text-black border-gray-300 dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-white dark:border-gray-600;
}

.empty-state p {
  @apply p-8 text-center text-gray-500 dark:text-gray-400 border border-gray-200 dark:border-white/10 rounded-md;
}

.ant-empty-image {
  @apply flex justify-center;
}

.avatar-container {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.avatar-container.is-active {
  z-index: 1;
  @apply ring-2 ring-indigo-500 ring-offset-2;
}

.avatar-bg-squared {
  background-size: contain;
}

.avatar-bg-rounded {
  background-size: cover;
}

.avatar-online {
  border: 2px solid #7de749 !important;
}

.avatar-offline {
  border: 2px solid #8E8E92 !important;
}

.status {
  @apply bottom-[-8px] right-[-8px] w-[20px] h-[20px];
}

.ant-tooltip-content .ant-tooltip-arrow-content {
  @apply bg-black !important;
}
.ant-tooltip-content .ant-tooltip-inner {
  @apply px-3 py-1 text-sm font-sans text-white rounded-lg shadow bg-black;
}
.ant-tooltip-content .ant-tooltip-inner p {
  @apply text-slate-300;
}

.spinner {
  display: flex;
  align-items: center;
}

as-skeleton {
  @apply flex-1;
}

as-skeleton-side-nav {
  @apply h-full flex flex-col;
}

#dropzone {
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 4;
  transition: opacity 175ms;
  @apply bg-purple-800/20 border-2 border-dashed border-purple-500;
}
#dropzone .dropzone-info {
  position: absolute;
  bottom: 50px;
  left: calc(50% - 130px);
  width: 260px;
  height: fit-content;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  transition: visibility 175ms, opacity 175ms;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0, rgba(0, 0, 0, 0.1) 0 1px 4px 0, rgba(0, 0, 0, 0.1) 0 2px 8px 0;
  @apply bg-white text-black dark:bg-slate-900 dark:text-white;
}
#dropzone .dropzone-info p {
  text-align: center;
  font-weight: 600;
}

.notification-detail p {
  padding-top: 5px;
  padding-bottom: 5px;
}
.notification-detail ul {
  list-style: circle;
  padding: 15px;
}

.ant-notification-notice {
  @apply pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5;
}
.ant-notification-notice .ant-notification-notice-message {
  @apply text-sm font-sans text-gray-900 dark:text-white;
}
.ant-notification-notice .ant-notification-notice-description {
  @apply mt-1 text-sm text-gray-500 dark:text-gray-400;
}

.cal-toolbar {
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  justify-content: center;
}

.calendar {
  @apply h-full min-h-[650px];
  /* Targeting odd tr elements within tbody of the FullCalendar time grid */
  /**
   * MULTI MONTH VIEW
   *
   */
}
.calendar .fc {
  @apply h-full;
}
.calendar .fc-view,
.calendar .fc-timeGridWeek-view {
  border-left-width: 0 !important;
  @apply border-l-0 !important;
}
.calendar .fc-col-header-cell-cushion {
  @apply capitalize;
}
.calendar .fc-timegrid-axis-cushion,
.calendar .fc-timegrid-slot-label {
  @apply dark:text-white;
}
.calendar .fc-event {
  border-radius: 7px;
}
.calendar .fc-timegrid-axis-cushion {
  text-transform: capitalize;
}
.calendar .fc-timegrid-slot {
  height: 40px !important;
}
.calendar .fc-timegrid-slots tr:nth-child(even) .fc-timegrid-slot-label {
  border-top-width: 0;
}
.calendar .fc-timegrid-slots tr:nth-child(even) .fc-timegrid-slot-lane {
  border-top-width: 0;
}
.calendar .fc .fc-daygrid-day.fc-day-today,
.calendar .fc .fc-timegrid-col.fc-day-today {
  @apply bg-slate-50 dark:bg-slate-900;
}
.calendar .fc-event-inner {
  display: flex;
  align-items: center;
}
.calendar .fc-theme-standard,
.calendar .fc-scrollgrid {
  @apply border-none !important;
}
.calendar .fc-theme-standard td,
.calendar .fc-theme-standard th {
  @apply border-gray-200 dark:border-gray-600 !important;
}
.calendar .fc-theme-standard td:last-child,
.calendar .fc-theme-standard th:last-child {
  border-right: 0;
}
.calendar .fc .fc-col-header-cell-cushion {
  @apply text-gray-900 dark:text-gray-100 py-3 !important;
}
.calendar .fc-col-header-cell {
  border-width: 0 0 1px;
}
.calendar .fc .fc-daygrid-day-number {
  @apply font-sans p-2 !important;
}
.calendar .fc-view {
  @apply border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 !important;
}
.calendar .fc-daygrid-dot-event .fc-event-title {
  @apply text-gray-900 dark:text-gray-100 !important;
}
.calendar .fc .fc-button {
  @apply rounded-md py-2;
}
.calendar .fc .fc-toolbar {
  @apply lg:flex-row flex-col items-start lg:items-center gap-2 !important;
}
.calendar .fc .fc-toolbar .fc-toolbar-chunk .fc-button-group {
  align-items: center !important;
  justify-content: center !important;
}
.calendar .fc .fc-toolbar .fc-toolbar-chunk .fc-button-group button {
  display: flex !important;
}
.calendar .fc .fc-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  text-transform: capitalize !important;
}
.calendar .fc .fc-button-primary {
  @apply bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100 font-sans !important;
}
.calendar .fc .fc-button-primary:focus {
  @apply shadow-none !important;
}
.calendar .fc .fc-button-primary:hover {
  @apply hover:bg-gray-50 dark:hover:bg-gray-600 hover:border-gray-300 dark:hover:border-gray-700 hover:text-gray-900 hover:dark:text-gray-100 !important;
}
.calendar .fc .fc-button-primary:disabled {
  @apply cursor-not-allowed bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100 !important;
}
.calendar .fc .fc-button-primary:disabled:hover {
  @apply bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100 !important;
}
.calendar .fc .fc-button-primary:not(:disabled):active:focus,
.calendar .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  @apply shadow-none !important;
}
.calendar .fc .fc-button-primary:not(:disabled):active,
.calendar .fc .fc-button-primary:not(:disabled).fc-button-active {
  @apply bg-gray-100 border border-gray-300 dark:bg-gray-500 dark:border-gray-500 text-gray-600 dark:text-gray-100 !important;
}
.calendar .fc .fc-dayGridMonth-button,
.calendar .fc .fc-timeGridDay-button,
.calendar .fc .fc-timeGridWeek-button,
.calendar .fc .fc-today-button {
  @apply px-8 capitalize !important;
}
.calendar .fc .fc-highlight {
  @apply bg-transparent !important;
}
.calendar .fc-daygrid-dot-event:hover,
.calendar .fc-daygrid-dot-event.fc-event-mirror {
  @apply bg-transparent !important;
}
.calendar .fc .fc-daygrid-event {
  @apply p-0 rounded-md mb-2 !important;
}
.calendar .fc-h-event {
  @apply rounded !important;
}
.calendar .fc .fc-cell-shaded,
.calendar .fc .fc-day-disabled {
  @apply bg-gray-200 dark:bg-gray-500 !important;
}
.calendar .fc-daygrid-bg-harness .fc-highlight {
  @apply bg-gray-100 dark:bg-gray-700 !important;
}
.calendar .fc-daygrid-event {
  @apply text-xs !important;
}
.calendar .fc .custom-calendar-event {
  @apply flex items-center min-h-[28px] p-1.5 rounded-md w-full overflow-hidden text-ellipsis !important;
}
.calendar .fc .fc-scrollgrid-section table {
  @apply w-full !important;
}
.calendar .fc .fc-daygrid-body {
  @apply w-full !important;
}
.calendar .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  @apply mx-0 !important;
}
.calendar .fc-toolbar-chunk {
  @apply flex flex-col sm:flex-row gap-2 w-full sm:w-auto !important;
}
.calendar .fc-view-harness .fc-multiMonthYear-view {
  @apply border-gray-200 dark:border-white/10 !important;
}
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-header .fc-multimonth-title {
  @apply text-gray-800 dark:text-white font-sans capitalize !important;
}
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-daygrid {
  @apply dark:bg-gray-800 !important;
}
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-daygrid .fc-multimonth-daygrid-table td.fc-day-disabled {
  @apply bg-gray-100 dark:bg-gray-700 !important;
}
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-daygrid .fc-multimonth-daygrid-table td.fc-day-past,
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-daygrid .fc-multimonth-daygrid-table td.fc-day-future {
  @apply text-gray-500 dark:text-gray-500 !important;
}
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-daygrid .fc-multimonth-daygrid-table td.fc-day-past .fc-daygrid-day-frame .fc-daygrid-day-events,
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-daygrid .fc-multimonth-daygrid-table td.fc-day-future .fc-daygrid-day-frame .fc-daygrid-day-events {
  bottom: -7px !important;
}
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-daygrid .fc-multimonth-daygrid-table td.fc-day-past .fc-daygrid-day-frame .fc-daygrid-day-events .fc-daygrid-day-bottom a,
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-daygrid .fc-multimonth-daygrid-table td.fc-day-future .fc-daygrid-day-frame .fc-daygrid-day-events .fc-daygrid-day-bottom a {
  @apply bg-indigo-50 border-indigo-100 text-indigo-700 dark:text-white dark:border-indigo-700 dark:bg-indigo-700 !important;
}
.calendar .fc-view-harness .fc-multiMonthYear-view .fc-multimonth-month .fc-multimonth-daygrid .fc-multimonth-daygrid-table td.fc-day-today {
  @apply bg-indigo-100 dark:bg-indigo-600 dark:text-white !important;
}

.rich-text-editor .ql-toolbar.ql-snow {
  @apply border border-gray-300 dark:border-gray-600 rounded-tl-lg rounded-tr-lg;
}
.rich-text-editor .ql-container.ql-snow {
  font-size: inherit !important;
  font-family: inherit !important;
  @apply border border-gray-300 dark:border-gray-600 rounded-bl-lg rounded-br-lg;
}
.rich-text-editor .ql-editor {
  @apply min-h-[120px] p-6;
}
.rich-text-editor .ql-snow .ql-stroke {
  @apply stroke-gray-600 dark:stroke-gray-100;
}
.rich-text-editor .ql-snow .ql-picker {
  @apply text-gray-600 dark:text-gray-100;
}
.rich-text-editor .ql-snow.ql-toolbar button,
.rich-text-editor .ql-snow .ql-toolbar button {
  @apply rounded;
}
.rich-text-editor .ql-snow.ql-toolbar button:hover,
.rich-text-editor .ql-snow .ql-toolbar button:hover,
.rich-text-editor .ql-snow.ql-toolbar button:focus,
.rich-text-editor .ql-snow .ql-toolbar button:focus,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  @apply bg-gray-100 dark:bg-gray-500;
}
.rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  @apply stroke-gray-900 dark:stroke-gray-100;
}
.rich-text-editor .ql-snow .ql-picker-options .ql-picker-item {
  @apply px-2 rounded-lg;
}
.rich-text-editor .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  @apply border-gray-300 dark:border-gray-600;
}
.rich-text-editor .ql-snow .ql-picker-options {
  @apply bg-white dark:bg-gray-700 rounded-lg;
}
.rich-text-editor .ql-snow.ql-toolbar button:hover,
.rich-text-editor .ql-snow .ql-toolbar button:hover,
.rich-text-editor .ql-snow.ql-toolbar button:focus,
.rich-text-editor .ql-snow .ql-toolbar button:focus,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  @apply text-gray-900 dark:text-gray-100;
}
.rich-text-editor .ql-editor.ql-blank::before {
  @apply text-gray-900 dark:text-gray-100 left-6 right-6;
}

.segmented-disabled-item, .segmented-disabled-item:hover, .segmented-disabled-item:focus {
  color: #00000040;
  cursor: not-allowed;
}

.segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012, 0 0 1px #00000014;
}

.segmented-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.ant-segmented {
  box-sizing: border-box;
  margin: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  padding: 2px;
  color: #000000a6;
  background-color: #0000000a;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  width: 100%;
}

.ant-segmented.ant-segmented-block {
  display: flex;
}

.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1;
  min-width: 0;
}

.ant-segmented:not(.ant-segmented-disabled):hover, .ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: #0000000f;
}

.ant-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012, 0 0 1px #00000014;
  color: #262626;
}

.ant-segmented-item:hover, .ant-segmented-item:focus {
  color: #262626;
}

.ant-segmented-item-label {
  min-height: 28px;
  padding: 0 11px;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.ant-segmented-item-icon + * {
  margin-left: 12px/2;
}

.ant-segmented-item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 36px;
  padding: 0 11px;
  font-size: 16px;
  line-height: 36px;
}

.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 20px;
  padding: 0 7px;
  line-height: 20px;
}

.ant-segmented-item-disabled, .ant-segmented-item-disabled:hover, .ant-segmented-item-disabled:focus {
  color: #00000040;
  cursor: not-allowed;
}

.ant-segmented-thumb {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012, 0 0 1px #00000014;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
}

.ant-segmented-thumb-motion-appear-active {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, width;
}

.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}

.ant-segmented.ant-segmented-rtl .ant-segmented-item-icon {
  margin-right: 0;
  margin-left: 6px;
}

.base-form {
  max-width: 640px;
}

form .label {
  display: block;
  padding-bottom: 0.5rem;
  @apply text-sm font-sans text-gray-700 dark:text-white;
}
form label.required:after {
  content: "*";
  color: #e11d48;
}
form .label-no-p {
  font-weight: 500;
  font-size: 0.8125rem;
  display: block;
  @apply text-sm font-sans text-gray-700 dark:text-white;
}
form .form-input {
  height: 100%;
  min-height: 2.5rem;
  width: 100%;
  padding: 0.325rem 0.75rem;
  transition: background 0.1s;
  @apply bg-white border border-gray-200 rounded-md shadow-sm text-sm dark:bg-transparent dark:border-gray-600 dark:text-white;
}
form .form-input:hover {
  @apply bg-gray-100 dark:bg-transparent;
}
form .form-input:disabled {
  @apply bg-gray-100 cursor-not-allowed;
}
form .form-input:focus {
  @apply bg-white border-purple-600 ring-purple-600 dark:bg-transparent;
}
form .form-input::placeholder {
  font-size: 14.5px;
  vertical-align: middle;
}
form input:not(:disabled),
form text-area:not(:disabled) {
  cursor: text;
}
form .ng-invalid.ng-dirty, form .ng-invalid.ng-dirty:focus, form .ng-invalid.ng-touched, form .ng-invalid.ng-touched:focus {
  border: none;
  box-shadow: none;
  @apply bg-white dark:bg-transparent rounded-md ring-2 ring-red-600;
}
form .form-select {
  @apply shadow-sm;
}
form nz-switch .ant-switch-checked {
  @apply bg-purple-600 !important;
}

.textarea-inline-control {
  border-color: transparent;
  padding: 7px 7px 8px;
  line-height: 1.28;
  resize: none;
  box-shadow: transparent 0 0 0 1px;
  transition: background 0.1s ease 0s;
  overflow: auto;
  overflow-y: hidden;
  width: 100%;
  border-radius: 3px;
  appearance: none;
  @apply w-full h-auto text-gray-900;
}
.textarea-inline-control:hover:not(:focus) {
  @apply bg-gray-50;
}
.textarea-inline-control:focus {
  @apply bg-white;
  border: 1px solid #9333ea;
  box-shadow: 0 0 0 1px #c084fc;
  outline: none;
}

.ant-input-group-addon {
  @apply rounded-tl-md rounded-bl-md min-w-[8rem] !important;
}
.ant-input-group-addon nz-select:hover {
  background: transparent !important;
}

.form-check .form-check-input {
  @apply rounded text-purple-600 focus:outline-none focus:ring-0;
}
.form-check input[type=radio],
.form-check input[type=checkbox] {
  @apply bg-white;
}
.form-check [type=checkbox]:checked,
.form-check [type=radio]:checked {
  @apply bg-purple-600;
}
.form-check .form-check-label {
  @apply font-sans text-gray-700 dark:text-white ml-2;
}

.input {
  @apply border
  border-gray-200
  dark:border-gray-600
  dark:bg-transparent
  rounded-md
  w-full
  py-2 px-3
  focus:outline-none
  focus:ring-1
  focus-within:ring-1
  focus-within:bg-transparent
  dark:focus-within:bg-transparent
  dark:focus:bg-transparent
  dark:focus:text-white
  dark:focus-within:text-white
  appearance-none
  transition
  duration-150
  ease-in-out;
}
.input:read-only {
  @apply bg-gray-100 dark:bg-gray-700 text-gray-400 cursor-not-allowed;
}
.input::placeholder {
  @apply dark:text-gray-300;
}
.input.input-invalid {
  @apply ring-1 ring-red-500 border-red-500 dark:bg-transparent;
}
.input.input-group {
  @apply rounded-br-none rounded-tr-none focus-within:z-10 !important;
}
.input.input-sm {
  @apply text-sm;
}
.input.input-lg {
  @apply text-lg h-12;
}
.input.input-md {
  @apply text-sm h-10;
}

.input-field {
  @apply h-10 focus:ring-purple-600 focus-within:ring-purple-600 focus-within:border-purple-600 focus:border-purple-600 dark:focus:bg-transparent;
}

.input-textarea {
  @apply focus:ring-purple-600 focus-within:ring-purple-600 focus-within:border-purple-600 focus:border-purple-600;
}

.input-field-sm {
  @apply h-9 focus:ring-purple-600 focus-within:ring-purple-600 focus-within:border-purple-600 focus:border-purple-600;
}

.input-addon {
  @apply flex items-center px-4 rounded-md border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700;
}

.input-textarea {
  min-height: 7rem;
}

.input-wrapper {
  @apply relative w-full flex;
}

.input-disabled {
  @apply bg-gray-100 dark:bg-gray-700 text-gray-400 cursor-not-allowed;
}
.input-disabled::placeholder {
  @apply opacity-70;
}

.input-suffix-start {
  @apply absolute top-2/4 transform -translate-y-2/4 left-2.5;
}

.input-suffix-end {
  @apply absolute top-2/4 transform -translate-y-2/4 right-2.5 flex;
}

.input-error {
  @apply absolute inset-y-0 right-0 m-1 pr-1.5 flex items-center pointer-events-none bg-white dark:bg-transparent;
}

.input-clear-icon {
  position: absolute;
  top: calc(50% - 0.6rem);
  right: 5px;
  z-index: 99;
}

nz-select {
  width: 100%;
}
nz-select.assignees nz-select-item {
  height: 32px !important;
  @apply rounded-md !important;
}
nz-select nz-select-top-control {
  height: 100%;
  min-height: 2.5rem;
  width: 100%;
  padding: 0.325rem 0.75rem;
  transition: background 0.1s;
  @apply bg-white border border-gray-200 rounded-md shadow-sm text-sm dark:text-slate-400 dark:border-slate-600 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-800 dark:hover:text-slate-200 focus:ring-purple-600 !important;
}
nz-select nz-select-top-control:hover nz-select-arrow {
  @apply text-gray-800 dark:text-slate-600 !important;
}
nz-select nz-select-top-control nz-select-item {
  display: flex;
  align-items: center;
}
nz-select nz-select-top-control nz-select-search input {
  height: 100% !important;
}
nz-select nz-select-top-control nz-select-search input::placeholder {
  font-size: 14.5px;
  vertical-align: middle !important;
}
nz-select nz-select-top-control nz-select-placeholder {
  display: flex;
  align-items: center;
}
nz-select.ant-select-focused nz-select-top-control {
  @apply bg-white dark:bg-gray-800 border-purple-600 ring-1 ring-purple-600 !important;
}
nz-select nz-select-arrow {
  @apply dark:text-slate-600 !important;
}

nz-option-container {
  @apply rounded-md bg-white dark:bg-gray-800 dark:border dark:border-slate-600 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none !important;
}
nz-option-container nz-option-item {
  @apply block px-4 py-2 text-sm text-gray-700 dark:text-slate-400 !important;
}
nz-option-container nz-option-item.ant-select-item-option-selected {
  @apply bg-gray-100 dark:text-white dark:bg-gray-700 !important;
}
nz-option-container nz-option-item.ant-select-item-option-active {
  @apply bg-gray-100 dark:text-white dark:bg-gray-700 !important;
}

nz-input-group .ant-input-wrapper .ant-input-group-addon {
  padding: 0 !important;
  border-width: 0;
  @apply bg-white dark:bg-gray-800;
}
nz-input-group .ant-input-wrapper .ant-input-group-addon nz-select nz-select-top-control {
  @apply rounded-tr-none rounded-br-none border-r-0 !important;
}
nz-input-group .ant-input-wrapper .ant-input-group-addon nz-select nz-select-top-control:focus {
  @apply border-r-2 !important;
}
nz-input-group .ant-input-wrapper .ant-input:hover {
  @apply bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-slate-200 border-gray-200 dark:border-slate-600 !important;
}
nz-input-group .ant-input-wrapper .ant-input:focus {
  @apply bg-white dark:text-white dark:bg-gray-800 border-purple-600 ring-purple-600 !important;
}

.ant-select-item-option {
  display: flex !important;
  flex-direction: row;
}

.search-list {
  @apply flex items-center px-6 mx-1.5 md:mx-auto lg:mx-0 xl:px-0 max-w-md;
}
.search-list .icon {
  @apply pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3;
}
.search-list .icon svg-icon {
  @apply dark:text-slate-400;
}
.search-list input {
  height: 100%;
  min-height: 2.5rem;
  width: 100%;
  transition: background 0.1s;
  @apply py-2 pl-10 pr-3 bg-white border border-gray-200 rounded-md shadow-sm text-sm dark:text-slate-400 dark:border-slate-600 dark:bg-gray-800;
}
.search-list input:hover {
  @apply bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-slate-200;
}
.search-list input:disabled {
  @apply bg-gray-100 dark:bg-gray-600 cursor-not-allowed;
}
.search-list input:focus {
  @apply bg-white dark:text-white dark:bg-gray-800 border-purple-600 ring-purple-600;
}
.search-list input::placeholder {
  font-size: 14.5px;
  vertical-align: middle;
}
.search-list .input-clear-icon {
  position: absolute;
  top: 0.5rem;
  right: 5px;
  z-index: 99;
}

nz-switch button {
  max-width: 38px;
  @apply bg-gray-200
    dark:bg-gray-600
    cursor-pointer
    transition-colors
    ease-in-out
    duration-200 !important;
}
nz-switch button.ant-switch-checked {
  @apply bg-purple-600 !important;
}
nz-switch button.ant-switch-disabled {
  @apply opacity-50 cursor-not-allowed !important;
}

nz-date-picker,
nz-time-picker {
  @apply h-10 rounded-md border-gray-300 hover:bg-gray-100 hover:text-gray-800 shadow-sm dark:bg-transparent dark:border-gray-600 !important;
}
nz-date-picker:hover,
nz-time-picker:hover {
  border-color: inherit !important;
}
nz-date-picker.ant-picker-focused,
nz-time-picker.ant-picker-focused {
  background: #fff;
  border: 1px solid #9333ea !important;
  box-shadow: 0 0 0 1px #c084fc !important;
  outline: none !important;
}
nz-date-picker input,
nz-time-picker input {
  font-size: 14.5px !important;
}

h1 {
  @apply text-2xl font-display text-slate-900 dark:text-white;
}

h2 {
  @apply text-xl font-display text-slate-900 dark:text-white;
}

h3 {
  @apply font-display text-slate-900 dark:text-white;
}

p {
  @apply text-sm font-sans text-slate-500 dark:text-slate-400;
}

label {
  @apply font-sans text-slate-700 dark:text-slate-50;
}

small {
  @apply text-slate-500 dark:text-slate-400;
}

em {
  @apply text-sm text-slate-300 dark:text-slate-600;
}

a {
  @apply font-sans text-purple-600 dark:text-purple-300 hover:text-purple-500 dark:hover:text-purple-500;
}

.animate-text {
  font-weight: normal;
  @apply text-3xl font-display text-slate-900 dark:text-white;
}

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}

[data-color-scheme=dark] {
  color-scheme: dark;
}

[data-color-scheme=light] {
  color-scheme: light;
}

as-project {
  width: 100%;
}

.toast {
  @apply flex
  flex-row
  items-center
  justify-start
  max-w-sm
  bg-white
  dark:bg-gray-700
  p-4
  text-white
  shadow-xl
  rounded-xl;
}
.toast .toast-icon-wrapper {
  @apply text-xl;
}
.toast .toast-content-wrapper {
  @apply flex flex-col items-start justify-center ml-4 cursor-default;
}

.ant-message-notice-content {
  padding: 0 !important;
  @apply bg-gray-900 dark:bg-gray-700 rounded-xl !important;
}
.ant-message-notice-content .ant-message-custom-content {
  @apply rounded-xl !important;
}
.ant-message-notice-content .ant-message-custom-content i {
  display: none;
}

.ant-modal-content {
  @apply rounded-xl border border-gray-500 border-opacity-20 ring-0 bg-white dark:bg-gray-800 !important;
}
.ant-modal-content .modal-title {
  @apply text-xl dark:text-white;
}
.ant-modal-content .modal-container {
  @apply mt-6;
}

.ant-drawer-body {
  @apply dark:bg-gray-800;
}

.drawer-header {
  @apply flex items-center;
}
.drawer-header .drawer-title {
  @apply font-display dark:text-white line-clamp-1;
}

.drawer-divider {
  @apply border-b border-gray-200 dark:border-gray-600 -mx-6 pt-4 mb-2;
}

.drawer-content {
  @apply pt-6;
}

.footer-container {
  @apply shadow bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-white/20;
}
.footer-container .footer-wrapper {
  @apply mx-auto max-w-full py-3 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8;
}
.footer-container .footer-wrapper .footer-r-wrapper {
  @apply flex justify-center space-x-6 md:order-2;
}
.footer-container .footer-wrapper .footer-r-wrapper .nav-item {
  @apply text-xs text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;
}
.footer-container .footer-wrapper .footer-r-wrapper .nav-item.disabled {
  cursor: not-allowed !important;
}
.footer-container .footer-wrapper .footer-l-wrapper {
  @apply flex justify-center mt-8 space-x-6 md:order-1 md:mt-0;
}
.footer-container .footer-wrapper .footer-l-wrapper .copyright {
  @apply text-center text-xs text-slate-500 dark:text-slate-400;
}
.footer-container .footer-wrapper .footer-l-wrapper .nav-item {
  @apply text-xs text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-white;
}

.quick-item {
  @apply flex items-center p-2 rounded-md bg-white dark:bg-gray-800 text-slate-500 hover:bg-gray-100 hover:text-slate-700 dark:text-slate-400 dark:hover:bg-slate-600 dark:hover:text-white;
}
.quick-item.active {
  @apply bg-purple-100 dark:bg-slate-600 text-slate-700 dark:text-white ring-1 ring-purple-600;
}

.itemIcon {
  @apply w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 select-none transition-colors duration-100 cursor-pointer;
}

.menu-item {
  @apply text-sm px-3 py-2 rounded-md font-sans text-gray-900;
}
.menu-item.active {
  @apply bg-gray-100 text-gray-800;
}

.user-session-popover {
  min-width: 270px;
  @apply divide-y divide-gray-100 dark:divide-white/10 bg-white dark:bg-gray-700 p-4 rounded-md shadow-lg;
}
.user-session-popover .submenu-item {
  @apply flex items-center relative py-2 px-3 rounded-sm text-slate-900 dark:text-white;
}
.user-session-popover .submenu-item:hover .not-implemented {
  @apply opacity-100;
}
.user-session-popover .submenu-item-fix {
  @apply flex items-center relative py-2 px-3 rounded-sm text-slate-900 dark:text-white;
}
.user-session-popover .active {
  @apply text-red-700 bg-amber-300;
}
.user-session-popover .allowed:hover {
  @apply bg-gray-200 dark:bg-gray-600 rounded-md;
}
.user-session-popover .not-allowed {
  cursor: not-allowed;
}

.notification-list {
  max-height: calc(100vh - 5rem);
}

.page-layout {
  @apply p-4;
}

.loader-wrapper {
  @apply w-full h-screen bg-gradient-to-bl from-[#F2F1F1] to-[#F9F9F6] dark:from-[#463982] dark:to-[#1B172F];
}
.loader-wrapper .loader-logo {
  position: absolute;
  top: 10%;
  left: 15%;
  z-index: 99;
}
.loader-wrapper .loader-inner {
  width: 700px;
  left: calc(50% - 350px);
  @apply absolute top-1/2 z-50;
}

.cursor {
  border-right-style: solid;
  animation: blink 1s infinite;
}

@keyframes cursor {
  to {
    opacity: 0;
  }
}
.starred-title {
  @apply text-gray-500 text-xs font-sans uppercase tracking-wide;
}

.starred-list {
  @apply grid grid-cols-1 gap-2 sm:gap-4 sm:grid-cols-2 xl:grid-cols-4 mt-3;
}

.nav-bar-aside {
  min-height: calc(100vh - 105px);
  height: 100%;
  @apply hidden xl:block xl:flex-shrink-0 xl:order-first h-full;
}
.nav-bar-aside .nav-bar-aside-container {
  @apply h-full relative flex flex-col w-64 border-r border-gray-200 bg-white dark:bg-gray-800 dark:border-gray-600;
}
.nav-bar-aside .nav-bar-aside-container .nav-bar-aside-selector {
  @apply flex-shrink-0;
}
.nav-bar-aside .nav-bar-aside-container .nav-bar-aside-selector .nav-bar-aside-selector-container {
  @apply h-16 px-6 flex flex-col justify-center;
}
.nav-bar-aside .nav-bar-aside-container .nav-bar-aside-filter {
  @apply flex flex-col flex-grow pb-4 overflow-y-auto;
}

.filter-item {
  @apply text-slate-700
  dark:text-slate-300
  flex
  items-center
  px-2
  py-2
  text-sm
  rounded-md;
}
.filter-item svg-icon {
  @apply text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0;
}
.filter-item:hover {
  @apply text-gray-900 bg-gray-100 dark:text-white dark:bg-slate-700;
}
.filter-item.active {
  @apply bg-gray-100 dark:bg-slate-700 text-slate-900 dark:text-white;
}

.chart-item {
  @apply block w-full px-3 py-2 flex-shrink-0 hover:bg-gray-100 hover:text-gray-900 rounded-md;
}
.chart-item .chart-title {
  @apply text-sm font-sans text-gray-600;
}
.chart-item .chart-subtitle {
  @apply text-xs font-sans text-gray-400;
}

.stacked-side {
  display: flex;
  /* Establishes a flex container */
  flex-direction: row;
  /* Aligns children horizontally */
}

.stacked-side-nav {
  flex-grow: 1;
  /* Takes up the remaining space */
}

.subscription-stat-card {
  @apply relative overflow-hidden rounded-md px-4 py-5 shadow sm:p-6 bg-white dark:bg-gray-700 border border-gray-200 dark:border-white/20;
}
.subscription-stat-card .pre-title {
  @apply truncate text-sm font-sans text-gray-500 dark:text-slate-400;
}
.subscription-stat-card .title {
  @apply mt-1 text-xl font-display tracking-tight text-gray-900 dark:text-white;
}
.subscription-stat-card .subtitle {
  @apply font-sans text-purple-600 dark:text-purple-400 hover:text-purple-500 dark:hover:text-purple-500;
}

.upgrade-banner {
  @apply md:flex
  md:items-center
  md:justify-between
  rounded-md
  md:space-x-5
  px-4
  py-5
  shadow
  sm:p-6
  border
  bg-white
  dark:bg-gray-700
  border-gray-200
  dark:border-white/20;
}
.upgrade-banner .upgrade-btn {
  @apply mt-6
    flex
    flex-col-reverse
    space-y-4
    space-y-reverse
    sm:flex-row-reverse
    sm:justify-end
    sm:space-y-0
    sm:space-x-3
    sm:space-x-reverse
    md:mt-0
    md:flex-row
    md:space-x-3;
}

.subscription-billing-card {
  @apply overflow-hidden bg-white dark:bg-gray-900 border border-gray-200 dark:border-white/20 shadow sm:rounded-md;
}
.subscription-billing-card .title {
  @apply font-display text-xl text-slate-900 dark:text-white;
}
.subscription-billing-card .subscription-billing-card-inner {
  @apply border-t border-gray-200 dark:border-white/20 px-4 py-5 sm:p-0;
}
.subscription-billing-card .subscription-billing-card-inner .subscription-billing-car-item {
  @apply py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 dark:bg-gray-800;
}

.subscription-usage-history {
  @apply bg-white dark:bg-gray-700 border border-gray-200 dark:border-white/20 overflow-hidden shadow rounded-md mt-4;
}

.ant-collapse {
  @apply shadow sm:rounded-tl-md sm:rounded-tr-md border border-b-0 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-700;
}

.side-nav-secondary {
  min-height: calc(100vh - 105px);
  height: 100%;
  @apply hidden lg:flex lg:flex-col w-64 h-full dark:bg-gray-800 border-r border-gray-200 dark:border-white/20;
}
.side-nav-secondary .side-nav-secondary-title {
  @apply h-16 flex items-center justify-between gap-4 pl-6 pr-4;
}
.side-nav-secondary .side-nav-secondary-title h5 {
  @apply font-sans text-slate-900 dark:text-white text-base;
}
.side-nav-secondary .side-nav-secondary-links {
  @apply px-4 space-y-0.5 flex-1;
}
.side-nav-secondary .side-nav-secondary-links h5 {
  @apply font-sans text-slate-900 dark:text-white text-base py-6;
}
.side-nav-secondary .side-nav-secondary-links .nav-link {
  @apply text-slate-700
      dark:text-slate-300
      flex
      items-center
      justify-between
      px-2
      py-2
      text-sm
      max-h-[40px]
      rounded-md;
}
.side-nav-secondary .side-nav-secondary-links .nav-link:hover {
  @apply text-gray-900 bg-gray-100 dark:text-white dark:bg-slate-700;
}
.side-nav-secondary .side-nav-secondary-links .nav-link.active {
  @apply bg-gray-100 dark:bg-slate-700 text-slate-900 dark:text-white;
}
.side-nav-secondary .side-nav-secondary-links .nav-link .link {
  @apply flex items-center;
}
.side-nav-secondary .side-nav-secondary-links .nav-link .link .dot {
  @apply flex-shrink-0 w-2.5 h-2.5 rounded-full mr-2;
}
.side-nav-secondary .side-nav-secondary-footer {
  @apply px-4 py-3;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  @apply text-gray-600 dark:text-gray-300 font-sans;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply text-purple-600 dark:text-purple-400;
}

.ant-tabs-ink-bar {
  @apply bg-purple-500;
}

.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before,
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  @apply dark:border-gray-600;
}

.nav-bar-aside-secondary {
  will-change: width;
  transition: width 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
  @apply hidden md:fixed md:inset-y-0 md:flex md:flex-col h-full relative;
}
.nav-bar-aside-secondary .nav-bar-aside-secondary-container {
  @apply flex flex-grow flex-col overflow-y-auto lg:border-r lg:border-gray-200 dark:lg:border-white/20 bg-gray-100 dark:bg-gray-900 pt-5;
}
.nav-bar-aside-secondary .nav-bar-aside-secondary-container .area-logo {
  @apply flex flex-shrink-0 items-center px-4;
}
.nav-bar-aside-secondary .nav-bar-aside-secondary-container .vertical-items {
  @apply mt-5 flex flex-1 flex-col;
}
.nav-bar-aside-secondary .nav-bar-aside-secondary-container .vertical-items .vertical-items-wrapper {
  @apply flex-1 space-y-1 px-3 pb-4;
}
.nav-bar-aside-secondary .nav-bar-aside-secondary-container .vertical-items .vertical-items-wrapper .item-title {
  will-change: visibility;
  transition: visibility 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
  @apply bg-gray-100 text-gray-900 font-sans flex items-center px-2 py-3 text-sm rounded-md;
}

.item-nav {
  @apply text-slate-700 hover:text-gray-900 hover:bg-gray-200 dark:text-slate-400 dark:hover:text-slate-100 dark:hover:bg-slate-700 flex items-center px-2 py-2 text-sm rounded-md;
}
.item-nav.active {
  @apply bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white;
}

.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  @apply bg-gray-100 dark:bg-slate-700;
}

.ant-segmented {
  @apply rounded-md bg-gray-100 dark:bg-slate-700;
}
.ant-segmented .ant-segmented-thumb {
  height: 100%;
  @apply bg-gray-100 dark:text-white dark:bg-gray-800 rounded-md !important;
}
.ant-segmented .ant-segmented-group {
  padding: 0 !important;
  height: 2.4rem;
  @apply rounded-md dark:bg-slate-700 p-1;
}
.ant-segmented .ant-segmented-group label {
  display: flex;
  align-items: center;
  position: relative !important;
}
.ant-segmented .ant-segmented-group label.ant-segmented-item-selected {
  @apply dark:text-white dark:bg-gray-800 rounded-md !important;
}
.ant-segmented .ant-segmented-group label.ant-segmented-item-selected a {
  @apply dark:text-white;
}
.ant-segmented .ant-segmented-group label .ant-segmented-item-label {
  display: flex;
  align-items: center;
  @apply dark:text-slate-300;
}
.ant-segmented .ant-segmented-group label .ant-segmented-item-label .segment-link {
  @apply inline-flex items-center px-1 text-sm text-slate-700 dark:text-slate-50 hover:text-purple-500 dark:hover:text-purple-300 !important;
}
.ant-segmented .ant-segmented-group label .ant-segmented-item-label .segment-link .segment-link-backdrop {
  min-width: 37px;
  @apply absolute top-0 bottom-0 right-0 bg-gray-100/70 dark:bg-gray-900/80 backdrop-opacity-10 backdrop-invert rounded-tr-md rounded-br-md;
}
.ant-segmented .ant-segmented-group label .ant-segmented-item-label .segment-link .segment-link-option {
  align-items: center;
  @apply mx-1.5 absolute top-0 bottom-0 right-0 !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
  @apply text-gray-500 dark:text-gray-400;
}
.ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination-next .ant-pagination-item-link:hover {
  @apply text-purple-500 dark:text-purple-400;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  @apply text-purple-500 dark:text-purple-400;
}

.ant-pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply bg-transparent rounded border-none;
}
.ant-pagination-item.ant-pagination-item-active {
  @apply bg-purple-50
    dark:bg-purple-500
    dark:bg-opacity-20
    hover:bg-purple-100
    dark:hover:bg-purple-500
    dark:hover:bg-opacity-30
    active:bg-purple-200
    dark:active:bg-purple-500
    dark:active:bg-opacity-40
    text-purple-600
    dark:text-purple-50;
}
.ant-pagination-item.ant-pagination-item-active a {
  @apply text-purple-600 dark:text-purple-50;
}
.ant-pagination-item a {
  @apply text-gray-500 dark:text-gray-400;
}
.ant-pagination-item a:hover {
  @apply text-purple-500 dark:text-purple-400;
}
.ant-pagination-item:hover a {
  @apply text-purple-500 dark:text-purple-400;
}

.ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination-jump-next .ant-pagination-item-link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-pagination-jump-prev .ant-pagination-item-link .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-link .ant-pagination-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination-jump-prev .ant-pagination-item-link .ant-pagination-item-container i,
.ant-pagination-jump-next .ant-pagination-item-link .ant-pagination-item-container i {
  @apply text-purple-500 dark:text-purple-400;
}
.ant-pagination-jump-prev .ant-pagination-item-link .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-link .ant-pagination-item-container .ant-pagination-item-ellipsis {
  display: flex;
  justify-content: center;
  align-items: center;
  @apply text-gray-500 dark:text-gray-400;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  justify-content: center;
}

.ant-pagination-options {
  height: 24px;
}
.ant-pagination-options nz-select {
  height: 24px;
}
.ant-pagination-options nz-select nz-select-top-control {
  min-height: 24px;
  height: 24px;
}
.ant-pagination-options nz-select nz-select-top-control nz-select-search input {
  font-size: 12px;
}
.ant-pagination-options nz-select nz-select-top-control nz-select-item {
  font-size: 12px;
}