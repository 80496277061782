.toast {
  @apply
  flex
  flex-row
  items-center
  justify-start
  max-w-sm
  bg-white
  dark:bg-gray-700
  p-4
  text-white
  shadow-xl
  rounded-xl;

  .toast-icon-wrapper {
    @apply text-xl;
  }

  .toast-content-wrapper {
    @apply flex flex-col items-start justify-center ml-4 cursor-default;
  }

}

.ant-message-notice-content {
  padding: 0 !important;
  @apply bg-gray-900 dark:bg-gray-700 rounded-xl #{!important};

  .ant-message-custom-content {
    @apply rounded-xl #{!important};
    i {
      display: none;
    }
  }
}
