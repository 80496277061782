.input {
  @apply
  border
  border-gray-200
  dark:border-gray-600
  dark:bg-transparent
  rounded-md
  w-full
  py-2 px-3
  focus:outline-none
  focus:ring-1
  focus-within:ring-1
  focus-within:bg-transparent
  dark:focus-within:bg-transparent
  dark:focus:bg-transparent
  dark:focus:text-white
  dark:focus-within:text-white
  appearance-none
  transition
  duration-150
  ease-in-out;

  &:read-only {
    @apply bg-gray-100 dark:bg-gray-700 text-gray-400 cursor-not-allowed;
  }

  &::placeholder {
    @apply dark:text-gray-300;
  }

  &.input-invalid {
    @apply ring-1 ring-red-500 border-red-500 dark:bg-transparent;
  }

  &.input-group {
    @apply rounded-br-none rounded-tr-none focus-within:z-10 #{!important};
  }

  &.input-sm {
    @apply text-sm;
  }

  &.input-lg {
    @apply text-lg h-12;
  }

  &.input-md {
    @apply text-sm h-10;
  }
}

.input-field {
  @apply h-10 focus:ring-purple-600 focus-within:ring-purple-600 focus-within:border-purple-600 focus:border-purple-600 dark:focus:bg-transparent;
}

.input-textarea {
  @apply focus:ring-purple-600 focus-within:ring-purple-600 focus-within:border-purple-600 focus:border-purple-600;
}

.input-field-sm {
  @apply h-9 focus:ring-purple-600 focus-within:ring-purple-600 focus-within:border-purple-600 focus:border-purple-600;
}

.input-addon {
  @apply flex items-center px-4 rounded-md border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700;
}

.input-textarea {
  min-height: 7rem;
}

.input-wrapper {
  @apply relative w-full flex;
}

.input-disabled {
  @apply bg-gray-100 dark:bg-gray-700 text-gray-400 cursor-not-allowed;

  &::placeholder {
    @apply opacity-70;
  }
}

.input-suffix-start {
  @apply absolute top-2/4 transform -translate-y-2/4 left-2.5;
}

.input-suffix-end {
  @apply absolute top-2/4 transform -translate-y-2/4 right-2.5 flex;
}

.input-error {
  @apply absolute inset-y-0 right-0 m-1 pr-1.5 flex items-center pointer-events-none bg-white dark:bg-transparent;
}

.input-clear-icon {
  position: absolute;
  top: calc(50% - 0.60rem);
  right: 5px;
  z-index: 99;
}
